import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { DefaultThemeProps } from 'styles/types'
import { ru, en } from 'pages/artists/filters/byAlphabet/alphabet'

type TProps = {
  setStartSymbol: React.Dispatch<React.SetStateAction<string | undefined>>
  reloadSingers: () => void
}

const ByAlphabet: FC<TProps> = ({ setStartSymbol, reloadSingers }) => {
  const [symbol, setSymbol] = useState('Все')

  useEffect(() => {
    if (symbol === 'Все' || symbol === '0 - 9') {
      reloadSingers()
    } else {
      setStartSymbol(symbol)
    }
  }, [symbol])

  return (
    <Container>
      <Wrapper>
        {ru.map((letter) => {
          return (
            <Button onClick={() => setSymbol(letter)} key={letter} $isActive={symbol === letter}>
              <Text>{letter}</Text>
            </Button>
          )
        })}
      </Wrapper>
      <Wrapper>
        {en.map((letter) => {
          return (
            <Button onClick={() => setSymbol(letter)} key={letter} $isActive={symbol === letter}>
              <Text>{letter}</Text>
            </Button>
          )
        })}
      </Wrapper>
    </Container>
  )
}

export default ByAlphabet

type TStyledProps = {
  $isActive?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 8px;
`
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 8px;
`
const Button = styled.div`
  border: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  border-radius: 8px;
  background-color: ${(props: DefaultThemeProps & TStyledProps) =>
    props.$isActive ? props.theme.background.active : 'inherit'};
  color: ${(props: DefaultThemeProps & TStyledProps) => props.$isActive && props.theme.text.active};
  padding: 6px;
  margin-bottom: 8px;
  &:not(:last-child) {
    margin-right: 8px;
  }
  cursor: pointer;
`
const Text = styled.span``
