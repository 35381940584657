import React from 'react'
import styled from 'styled-components'
import { useScrollInToView } from 'hooks/useScrollInToView'

const Favorites = () => {
  const { ref } = useScrollInToView()

  return <Container ref={ref}>Favorites</Container>
}

export default Favorites

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`
