import { client_request } from 'api'
import { AppDispatch } from 'store/store'
import { setGenres, setHasMoreSongs, setMoreSongs, setOffset, setSongs } from 'store/modules/catalog/reducer'
import { LIMIT } from 'shared/constants'

export const getGenres = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await client_request.get('/reference/genres')
      dispatch(setGenres(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const getSongs = (
  url: string,
  getMore: boolean,
  limit: number,
  offset?: number,
  searchStr?: string,
  genreId?: string,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setHasMoreSongs(true))

    if (!getMore) {
      dispatch(setSongs([]))
      dispatch(setOffset(LIMIT))
    }

    try {
      const response = await client_request.get(url, {
        params: {
          limit: limit,
          offset: offset,
          genreId: genreId,
          searchStr: searchStr,
        },
      })
      if (response.data.data.length < limit) {
        dispatch(setHasMoreSongs(false))
      }
      if (getMore) {
        dispatch(setMoreSongs(response.data.data))
      } else {
        dispatch(setSongs(response.data.data))
      }
    } catch (error) {
      console.log(error)
    }
  }
}
