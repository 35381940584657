export enum ERoutes {
  home = '/',
  catalog = '/catalog',
  artists = '/artists',
  favorites = '/favorites',
  playlists = '/playlists',
  sign_in = '/sign_in',
  sign_up = '/sign_up',
  profile = '/profile',
  selection = '/selection',
  playlist = '/playlist',
  player = '/player',
  recovery = '/recovery',
}
