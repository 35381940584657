import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { store } from 'store/store'
import { Provider } from 'react-redux'
import './i18next/i18n'

const container = document.getElementById('root')

const Root = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

if (container) {
  const root = ReactDOM.createRoot(container)
  root.render(<Root />)
}
