import { client_request } from 'api'
import { AppDispatch } from 'store/store'
import { setPlaylist } from 'store/modules/playlist/reducer'

export const getPlaylist = (id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await client_request.get(`/guest/playlists/${id}`)
      dispatch(setPlaylist(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}
