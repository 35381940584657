import React, { FC } from 'react'
import styled from 'styled-components'
import { DefaultThemeProps } from 'styles/types'

type TProps = {
  title: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const Button: FC<TProps> = ({ title, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Title>{title}</Title>
    </Container>
  )
}

export default Button

const Container = styled.button`
  border: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  background-color: ${(props: DefaultThemeProps) => props.theme.background.primary};
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: ${(props: DefaultThemeProps) => props.theme.background.hover};
  }
`
const Title = styled.span``
