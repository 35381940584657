import React, { FC } from 'react'
import styled from 'styled-components'
import { screens } from 'styles/screens'
import { CardTitle } from 'shared/ui/slider'

type TProps = {
  name: string
  img: string
  onClick?: () => void
}

const Round: FC<TProps> = ({ name, img, onClick }) => {
  return (
    <Card onClick={onClick}>
      <ImgWrapper>
        <Image src={img} alt="Cover" />
      </ImgWrapper>

      <Title>{name}</Title>
    </Card>
  )
}

export default Round

const Card = styled.div`
  width: calc(var(--index) * 8);
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  cursor: pointer;
  @media (max-width: ${screens.lg}px) {
    width: calc(var(--index) * 10);
  }
  @media (max-width: ${screens.md}px) {
    width: calc(var(--index) * 10);
  }
  @media (max-width: ${screens.sm}px) {
    width: calc(var(--index) * 12);
  }
`
const ImgWrapper = styled.div`
  height: calc(var(--index) * 8);
  position: relative;
  border-radius: 50%;
  margin-bottom: 16px;
  @media (max-width: ${screens.lg}px) {
    height: calc(var(--index) * 10);
  }
  @media (max-width: ${screens.md}px) {
    height: calc(var(--index) * 10);
  }
  @media (max-width: ${screens.sm}px) {
    height: calc(var(--index) * 12);
  }
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  object-fit: cover;
`
const Title = styled(CardTitle)`
  align-self: center;
`
