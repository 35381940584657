import React, { useEffect } from 'react'
import styled from 'styled-components'
import { screens } from 'styles/screens'
import Footer from 'shared/layout/footer'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFiltersFromStore,
  getHasMoreSongsFromStore,
  getOffsetFromStore,
  getSongsFromStore,
} from 'store/modules/catalog/selectors'
import { getSongs } from 'store/modules/catalog/actions'
import { LIMIT } from 'shared/constants'
import SongsWithInfiniteLoading from 'shared/songsTable/tableWithInfiniteLoading'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useScrollInToView } from 'hooks/useScrollInToView'
import { config } from 'config/config'
import { ERoutes } from 'routes/routes'
import { setPlayingSongParams } from 'store/modules/player/reducer'
import { TSong } from 'store/modules/catalog/types'

const Selection = () => {
  const dispatch = useDispatch()
  const { ref } = useScrollInToView()

  const navigate = useNavigate()
  const [params] = useSearchParams()
  const url = params.get('url')
  const title = params.get('title')
  const subtitle = params.get('subtitle')
  const bannerUrl = params.get('bannerUrl')
  const genreId = params.get('genreId')

  const songs = useSelector(getSongsFromStore)
  const hasSongsMore = useSelector(getHasMoreSongsFromStore)
  const offset = useSelector(getOffsetFromStore)
  const filters = useSelector(getFiltersFromStore)

  useEffect(() => {
    if (url) {
      dispatch(getSongs(url, false, LIMIT, undefined, undefined, genreId ? genreId : undefined))
    }
  }, [])

  const onSongClick = (song: TSong) => {
    dispatch(
      setPlayingSongParams({
        id: song.id,
        catalogPhonogramNumber: song.catalogPhonogramNumber,
        name: song.name,
        singerName: song.singerName,
      }),
    )
    navigate(ERoutes.player)
  }

  return (
    <Container ref={ref}>
      <Image
        src={bannerUrl ? `${config.storage}/${bannerUrl}` : `https://via.placeholder.com/1000x300.png?text=${title}`}
        alt="hero"
      />
      <Wrapper>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        {url && (
          <SongsWithInfiniteLoading
            url={url}
            songs={songs}
            hasSongsMore={hasSongsMore}
            offset={offset}
            filters={{ ...filters, genreId: genreId ? genreId : undefined }}
            onSongClick={onSongClick}
          />
        )}
        <Footer />
      </Wrapper>
    </Container>
  )
}

export default Selection

const Container = styled.div``
const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  margin: 0 0 8px 0;
`
const SubTitle = styled.h2`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 16px 0;
`
const Image = styled.img`
  width: 100%;
  height: calc(var(--index) * 8);
  object-fit: cover;
  margin-bottom: 16px;
`
const Wrapper = styled.div`
  padding: 0 20px;
  @media (max-width: ${screens.md}px) {
    padding: 0 10px;
  }
`
