import React, { FC } from 'react'
import { AutoSizer, InfiniteLoader, List, ListRowProps } from 'react-virtualized'
import styled from 'styled-components'
import './tableInner.css'
import { useDispatch } from 'react-redux'
import { setOffset } from 'store/modules/singers/reducer'
import { LIMIT } from 'shared/constants'
import { TSinger } from 'store/modules/singers/types'
import ArtistsTableRow from 'pages/artists/table/row'
import { getSingers } from 'store/modules/singers/actions'

type TProps = {
  singers: TSinger[]
  hasMoreSingers: boolean
  offset: number
  startSymbol: string | undefined
}

const ArtistsTable: FC<TProps> = ({ singers, offset, hasMoreSingers, startSymbol }) => {
  const dispatch = useDispatch()

  const rowRenderer = ({ index, key, style }: ListRowProps) => {
    const singer = singers[index]
    return (
      <RowWrapper key={key} style={style}>
        {singer ? <ArtistsTableRow singer={singer} index={index} /> : 'Loading...'}
      </RowWrapper>
    )
  }

  const loadMoreRows = async () => {
    await dispatch(setOffset(offset + LIMIT))
    await dispatch(getSingers(true, LIMIT, offset, undefined, undefined, startSymbol))
  }

  return (
    <AutoSizerWrapper>
      {singers.length > 0 ? (
        <InfiniteLoader
          isRowLoaded={({ index }) => !!singers[index]}
          loadMoreRows={loadMoreRows}
          rowCount={!hasMoreSingers ? singers.length : Number.MAX_SAFE_INTEGER}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                  width={width}
                  height={height}
                  rowCount={singers.length}
                  rowHeight={64}
                  rowRenderer={rowRenderer}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      ) : (
        <EmptyList>Артистов неть ... :(((</EmptyList>
      )}
    </AutoSizerWrapper>
  )
}

export default ArtistsTable

const AutoSizerWrapper = styled.div`
  height: 80vh;
  flex: 1 1 auto;
`
const EmptyList = styled.div`
  text-align: center;
  padding: 30px 10px;
`
const RowWrapper = styled.div``
