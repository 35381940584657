import React, { FormEvent } from 'react'
import styled from 'styled-components'
import { screens } from 'styles/screens'
import { DefaultThemeProps } from 'styles/types'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getFiltersFromStore } from 'store/modules/catalog/selectors'
import { setFilters } from 'store/modules/catalog/reducer'

const ByString = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const filters = useSelector(getFiltersFromStore)

  const onInput = (e: FormEvent<HTMLInputElement>) => {
    const searchStr = e.currentTarget.value

    if (searchStr.length > 2) {
      dispatch(setFilters({ ...filters, searchStr }))
    }

    if (searchStr.length <= 2) {
      if (!filters.searchStr) return
      dispatch(setFilters({ ...filters, searchStr: undefined }))
    }
  }

  return (
    <Search>
      <StyledSearchIcon />
      <Input onInput={onInput} placeholder={t('catalog.search_placeholder')} />
    </Search>
  )
}

export default ByString

const Search = styled.div`
  width: 50%;
  display: flex;
  position: relative;
  align-items: center;
  @media (max-width: ${screens.lg}px) {
    width: 100%;
  }
`
const Input = styled.input`
  width: 100%;
  border: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  border-radius: 8px;
  padding: 10px 10px 10px 40px;
`
const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 10px;
  z-index: 10;
`
