import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DefaultThemeProps } from 'styles/types'
import { ReactComponent as UserAvatar } from 'assets/icons/user_avatar_v2.svg'
import { useSelector } from 'react-redux'
import { getUser } from 'store/modules/profile/selectors'
import Button from 'components/button'
import EditProfile from 'pages/account/profile/edit'

const Profile = () => {
  const { t } = useTranslation()
  const user = useSelector(getUser)

  const [show, setShow] = useState(false)
  const showHideEditModal = () => setShow((prevState) => !prevState)

  return (
    <Container>
      <Title>{t('profile.title')}</Title>
      <LogoFIO>
        <UserAvatar />
        <FIO>
          <Text>{user?.firstName || ' - '}</Text>
          <Text>{user?.lastName || ' - '}</Text>
        </FIO>
      </LogoFIO>
      <Email>{user?.email || ' - '}</Email>
      <Phone>{user?.phone || ' - '}</Phone>
      <Button title={t('profile.button')} onClick={showHideEditModal} />
      {show && user && <EditProfile showHideEditModal={showHideEditModal} user={user} />}
    </Container>
  )
}

export default Profile

export const Container = styled.div`
  border-bottom: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  padding-bottom: 32px;
  margin-bottom: 16px;
`
export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 16px 0;
`
export const LogoFIO = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`
export const FIO = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
`
export const Text = styled.span``
export const Email = styled.span`
  display: block;
  margin-bottom: 16px;
`
export const Phone = styled(Email)``
