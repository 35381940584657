import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TUiState } from 'store/modules/ui/types'

const initialState: TUiState = {
  theme: 'light',
  showSidebar: true,
  isMobileDevise: false,
  settings: null,
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setTheme(state: TUiState, action: PayloadAction<string>) {
      state.theme = action.payload
    },
    setShowSidebar(state: TUiState, action: PayloadAction<boolean>) {
      state.showSidebar = action.payload
    },
    setIsMobileDevise(state: TUiState, action: PayloadAction<boolean>) {
      state.isMobileDevise = action.payload
    },
    setSettings(state: TUiState, action: PayloadAction<{ cdnUrl: string } | null>) {
      state.settings = action.payload
    },
  },
})

export const { setTheme, setShowSidebar, setIsMobileDevise, setSettings } = uiSlice.actions

export default uiSlice.reducer
