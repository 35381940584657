import { client_request } from 'api'
import { AppDispatch } from 'store/store'
import { setSongUrl } from 'store/modules/player/reducer'
import { toast } from 'react-toastify'

// function changeVideoSource(blob: Blob) {
//   const blobUrl = URL.createObjectURL(blob)
//   console.log(`Changing video source to blob URL "${blobUrl}"`)
// }
//
// function fetchVideo(url: string) {
//   return fetch(url).then(function (response) {
//     return response.blob()
//   })
// }

export const getSongUrl = (songId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await client_request.get(`/guest/phonograms/${songId}/assembled-container-link`)
      dispatch(setSongUrl(response.data.data.link))

      // fetchVideo('https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4').then(
      //   function (blob) {
      //     changeVideoSource(blob)
      //   },
      // )
    } catch (error: any) {
      console.log(error.response)
      dispatch(setSongUrl(''))
      if (error.response.status === 400) {
        toast('Караоке файл отсутствует')
      }
    }
  }
}
