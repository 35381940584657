import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { Container, freshest_slider_settings, SliderWrapper, Title } from 'shared/ui/slider'
import RectangularWithDescription from 'components/cards/rectangularWithDescription'
import { TPlaylistInList } from 'store/modules/main/types'
import { config } from 'config/config'
import { useNavigate } from 'react-router-dom'
import { ERoutes } from 'routes/routes'

type TProps = {
  freshest: TPlaylistInList[]
}

const Freshest: FC<TProps> = ({ freshest }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const redirectToPlaylist = (id: string) => navigate(ERoutes.playlist + '/' + `${id}`)

  return (
    <Container>
      <Title>{t('freshest.title')}</Title>
      <SliderWrapper>
        <Slider {...freshest_slider_settings}>
          {freshest.map((playlist) => (
            <RectangularWithDescription
              onClick={() => redirectToPlaylist(playlist.id)}
              key={playlist.id}
              playlist={playlist}
              img={
                playlist.playlistImage
                  ? `${config.storage}/${playlist.playlistImage.relativePath}`
                  : `https://via.placeholder.com/250x450.png?text=${playlist.name}`
              }
            />
          ))}
        </Slider>
      </SliderWrapper>
    </Container>
  )
}

export default Freshest
