import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { Container, popular_slider_settings, SliderWrapper, Title } from 'shared/ui/slider'
import Round from 'components/cards/round'
import { TSinger } from 'store/modules/singers/types'
import { ERoutes } from 'routes/routes'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { config } from 'config/config'

type TProps = {
  singers: TSinger[]
}

const Artists: FC<TProps> = ({ singers }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goToSingersSelection = (title: string, singerId: string) => {
    navigate({
      pathname: ERoutes.selection,
      search: createSearchParams({
        title: title,
        url: `/singers/${singerId}/phonograms`,
      }).toString(),
    })
  }

  return (
    <Container>
      <Title>{t('artists.title')}</Title>
      <SliderWrapper>
        <Slider {...popular_slider_settings}>
          {singers.map((singer) => (
            <Round
              key={singer.id}
              name={singer.name}
              onClick={() => goToSingersSelection(singer.name, singer.id)}
              img={
                singer.photo
                  ? `${config.storage}/${singer.photo.relativePath}`
                  : `https://via.placeholder.com/250x450.png?text=${singer.name}`
              }
            />
          ))}
        </Slider>
      </SliderWrapper>
    </Container>
  )
}

export default Artists
