import React, { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

type TProps = {
  volume: number
  playbackRate: number
  onVolumeChange: (value: number | number[]) => void
  onPlaybackRateChange: (value: number | number[]) => void
  toggleShowSettings: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

const trackStyle = { height: 4, backgroundColor: 'white', borderRadius: 4 }
const railStyle = {
  height: 4,
  borderRadius: 4,
  backgroundColor: '#35383D',
}
const handleStyle = {
  height: 8,
  width: 8,
  backgroundColor: 'white',
  opacity: 1,
  border: 'none',
  boxShadow: 'none',
  borderRadius: '50%',
  cursor: 'default',
  marginTop: -2,
}

const Settings: FC<TProps> = ({ volume, onVolumeChange, playbackRate, onPlaybackRateChange, toggleShowSettings }) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = (event: any) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      toggleShowSettings(event)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    return function () {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <Container ref={ref}>
      <Setting>
        <Label>Громкость</Label>
        <RangeWrapper>
          <Text>0</Text>
          <Slider
            min={0}
            max={1}
            step={0.01}
            defaultValue={volume}
            onChange={onVolumeChange}
            trackStyle={trackStyle}
            railStyle={railStyle}
            handleStyle={handleStyle}
            style={{
              marginRight: 16,
              marginLeft: 16,
            }}
          />
          <Text>100</Text>
        </RangeWrapper>
      </Setting>

      <Setting>
        <Label>Скорость воспроизведения</Label>
        <RangeWrapper>
          <Text>-50%</Text>
          <Slider
            min={0.5}
            max={1.5}
            step={0.01}
            startPoint={1}
            defaultValue={playbackRate}
            onChange={onPlaybackRateChange}
            trackStyle={trackStyle}
            railStyle={railStyle}
            handleStyle={handleStyle}
            style={{
              marginRight: 16,
              marginLeft: 16,
            }}
          />
          <Text>+50%</Text>
        </RangeWrapper>
      </Setting>
    </Container>
  )
}

export default Settings

const Container = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  position: absolute;
  bottom: 100px;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  padding: 16px;
`
const Setting = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`
const RangeWrapper = styled.div`
  display: flex;
  align-items: center;
`
const Label = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`
const Text = styled.span`
  color: #666a71;
`
