import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import screenfull from 'screenfull'
import Controls from 'pages/player/controls'
import Queue from 'pages/player/queue'
import { ReactComponent as QueueIcon } from 'assets/icons/queue.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getPlayingSongParamsFromStore, getQueueFromStore, getSongUrlFromStore } from 'store/modules/player/selectors'
import { setPlayingSongParams, setSongUrl } from 'store/modules/player/reducer'
import { useNavigate } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'
import { getSongUrl } from 'store/modules/player/actions'

type TPlayerState = {
  showQueue: boolean
  playing: boolean
  volume: number
  playbackRate: number
  fullscreen: boolean
  duration: number
  progress: number
}

const Player = () => {
  const playerContainer = useRef(null)
  const player = useRef<ReactPlayer>(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const playingSongParams = useSelector(getPlayingSongParamsFromStore)
  const queue = useSelector(getQueueFromStore)
  const songUrl = useSelector(getSongUrlFromStore)

  const goBack = () => navigate(-1)

  const [playerState, setPlayerState] = useState<TPlayerState>({
    showQueue: false,
    playing: true,
    volume: 1,
    playbackRate: 1,
    fullscreen: false,
    duration: 0,
    progress: 0,
  })

  const toggleShowQueue = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation()
    setPlayerState((prevState) => ({ ...prevState, showQueue: !prevState.showQueue }))
  }
  const togglePlaying = () => setPlayerState((prevState) => ({ ...prevState, playing: !prevState.playing }))
  const onVolumeChange = (value: number | number[]) => setPlayerState((prevState) => ({ ...prevState, volume: +value }))
  const onPlaybackRateChange = (value: number | number[]) =>
    setPlayerState((prevState) => ({ ...prevState, playbackRate: +value }))
  const toggleFullscreen = async () => {
    if (playerContainer.current && screenfull.isEnabled) {
      await screenfull.toggle(playerContainer.current)
    }
    setPlayerState((prevState) => ({ ...prevState, fullscreen: !prevState.fullscreen }))
  }
  const onDuration = (duration: number) => setPlayerState((prevState) => ({ ...prevState, duration: duration }))
  const onProgress = (progress: { playedSeconds: number }) =>
    setPlayerState((prevState) => ({ ...prevState, progress: progress.playedSeconds }))

  const rewind = (time: number) => {
    player?.current?.seekTo(time)
  }

  const playNext = () => {
    const playingSongIndex = queue.findIndex((song) => song.id === playingSongParams?.id)

    if (playingSongIndex >= 0) {
      const nextSong = queue[playingSongIndex + 1]
      if (nextSong) {
        dispatch(
          setPlayingSongParams({
            id: nextSong.id,
            catalogPhonogramNumber: nextSong.catalogPhonogramNumber,
            name: nextSong.name,
            singerName: nextSong.singerName,
          }),
        )
      }
    } else if (queue.length > 0) {
      const nextSong = queue[0]
      dispatch(
        setPlayingSongParams({
          id: nextSong.id,
          catalogPhonogramNumber: nextSong.catalogPhonogramNumber,
          name: nextSong.name,
          singerName: nextSong.singerName,
        }),
      )
    }
  }

  useEffect(() => {
    if (playingSongParams) {
      dispatch(getSongUrl(playingSongParams.id))
    }
  }, [playingSongParams])

  useEffect(() => {
    const callback = () => {
      setPlayerState((prevState) => {
        console.log(prevState)
        return { ...prevState, fullscreen: screenfull.isFullscreen }
      })
    }
    if (screenfull.isEnabled) {
      screenfull.on('change', callback)
    }

    return function () {
      screenfull.off('change', callback)
      dispatch(setPlayingSongParams(null))
      dispatch(setSongUrl(''))
    }
  }, [])

  return (
    <Container ref={playerContainer}>
      <PlayerWrapper>
        {playingSongParams ? (
          <>
            <ReactPlayer
              ref={player}
              url={songUrl}
              width="100%"
              height="100%"
              playing={playerState.playing}
              volume={playerState.volume}
              playbackRate={playerState.playbackRate}
              style={{ backgroundColor: 'black' }}
              progressInterval={100}
              onProgress={onProgress}
              onDuration={onDuration}
              onEnded={playNext}
            />

            <Controls
              volume={playerState.volume}
              playbackRate={playerState.playbackRate}
              onVolumeChange={onVolumeChange}
              onPlaybackRateChange={onPlaybackRateChange}
              playing={playerState.playing}
              togglePlaying={togglePlaying}
              fullscreen={playerState.fullscreen}
              toggleFullscreen={toggleFullscreen}
              duration={playerState.duration}
              progress={playerState.progress}
              rewind={rewind}
              playingSongParams={playingSongParams}
              playNext={playNext}
            />
          </>
        ) : (
          <NoParams>Выберите песню из каталога или из очереди воспроизведения</NoParams>
        )}
        <GoBack onClick={goBack}>
          <StyledBiArrowBack />
        </GoBack>
        {queue.length > 0 && <QueueButton onClick={toggleShowQueue} />}
      </PlayerWrapper>
      {playerState.showQueue && (
        <Queue
          playingSongId={playingSongParams?.id || ''}
          fullscreen={playerState.fullscreen}
          toggleShowQueue={toggleShowQueue}
        />
      )}
    </Container>
  )
}

export default Player

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: black;
`
const PlayerWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
`
const NoParams = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  color: white;
`
const QueueButton = styled(QueueIcon)`
  width: 36px;
  height: 36px;
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 8px;
  margin: 10px 10px 0 0;
`
const GoBack = styled.div`
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  position: absolute;
  left: 0;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  padding: 8px;
  margin: 10px 0 0 10px;
`
const StyledBiArrowBack = styled(BiArrowBack)`
  width: 24px;
  height: 24px;
  color: gray;
`
