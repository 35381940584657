import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { Container, popular_slider_settings, SliderWrapper, Title } from 'shared/ui/slider'
import Square from 'components/cards/square'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { ERoutes } from 'routes/routes'
import { TGenreItem } from 'store/modules/catalog/types'
import { config } from 'config/config'

type TProps = {
  genres: TGenreItem[]
}

const Genres: FC<TProps> = ({ genres }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const goToGenresSelection = (title: string, genreId: string, banner: string) => {
    navigate({
      pathname: ERoutes.selection,
      search: createSearchParams({
        title: title,
        url: 'guest/phonograms',
        genreId: genreId,
        bannerUrl: banner,
      }).toString(),
    })
  }

  return (
    <Container>
      <Title>{t('genres.title')}</Title>
      <SliderWrapper>
        <Slider {...popular_slider_settings}>
          {genres.map((genre) => (
            <Square
              key={genre.id}
              onClick={() => goToGenresSelection(genre.name, genre.id, genre.banner ? genre.banner.relativePath : '')}
              name={genre.name}
              img={
                genre.coverImage
                  ? `${config.storage}/${genre.coverImage.relativePath}`
                  : `https://via.placeholder.com/250x250.png?text=${genre.name}`
              }
            />
          ))}
        </Slider>
      </SliderWrapper>
    </Container>
  )
}

export default Genres
