import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TPLayerState, TSongInfo } from 'store/modules/player/types'
import { TSong } from 'store/modules/catalog/types'

const initialState: TPLayerState = {
  songUrl: '',
  playingSongParams: null,
  queue: [],
}

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setSongUrl(state: TPLayerState, action: PayloadAction<string>) {
      state.songUrl = action.payload
    },
    setPlayingSongParams(state: TPLayerState, action: PayloadAction<TSongInfo | null>) {
      state.playingSongParams = action.payload
    },
    addSongInQueue(state: TPLayerState, action: PayloadAction<TSong>) {
      state.queue.push(action.payload)
    },
    removeSongFromQueue(state: TPLayerState, action: PayloadAction<string>) {
      state.queue = state.queue.filter((song) => song.id !== action.payload)
    },
    clearQueue(state: TPLayerState, action: PayloadAction<[]>) {
      state.queue = action.payload
    },
  },
})

export const { setPlayingSongParams, addSongInQueue, clearQueue, removeSongFromQueue, setSongUrl } = playerSlice.actions

export default playerSlice.reducer
