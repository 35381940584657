import React from 'react'
import { GoBack } from 'components/goBack'
import { useForm, SubmitHandler } from 'react-hook-form'
import { TSignInForm } from 'store/modules/profile/types'
import { useDispatch, useSelector } from 'react-redux'
import { signIn } from 'store/modules/profile/actions'
import { getIsLoading } from 'store/modules/loading/selectors'
import { Loader } from 'components/loader'
import { ERoutes } from 'routes/routes'
import { Link, useNavigate } from 'react-router-dom'
import { Main } from 'shared/ui/containers'
import { Form, FormWrapper } from 'shared/ui/form'
import { Input, InputWrapper, Label } from 'shared/ui/input'
import { Button } from 'shared/ui/button'
import { A } from 'shared/ui/link'

const SignIn = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)
  const navigate = useNavigate()

  const { register, handleSubmit } = useForm<TSignInForm>()

  const goHome = () => navigate(ERoutes.home)

  const onSubmit: SubmitHandler<TSignInForm> = (data) => {
    dispatch(signIn(data, goHome))
  }

  return (
    <Main>
      {isLoading && <Loader isLoading={isLoading} />}
      <GoBack text="Home page" redirectTo={ERoutes.home} />
      <FormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper>
            <Label htmlFor="email">Email</Label>
            <Input id="email" type="email" {...register('email', { required: true })} />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="password">Password</Label>
            <Input id="password" type="password" {...register('password', { required: true })} />
          </InputWrapper>

          <Button>Sign in</Button>

          <Link to={ERoutes.sign_up}>
            <A>Sing up</A>
          </Link>
        </Form>
      </FormWrapper>
    </Main>
  )
}

export default SignIn
