import React, { FC } from 'react'
import { config } from 'config/config'
import cover from 'assets/images/song_cover.jpg'
import styled from 'styled-components'
import { TSong } from 'store/modules/catalog/types'
import { DefaultThemeProps } from 'styles/types'
import { CgClose, CgPlayButtonO } from 'react-icons/cg'
import { useDispatch } from 'react-redux'
import { removeSongFromQueue, setPlayingSongParams } from 'store/modules/player/reducer'

type TProps = {
  song: TSong
  fullscreen: boolean
  playingSongId: string
}

const QueueItem: FC<TProps> = ({ song, fullscreen, playingSongId }) => {
  const dispatch = useDispatch()

  const remove = () => dispatch(removeSongFromQueue(song.id))
  const play = () =>
    dispatch(
      setPlayingSongParams({
        id: song.id,
        catalogPhonogramNumber: song.catalogPhonogramNumber,
        name: song.name,
        singerName: song.singerName,
      }),
    )

  return (
    <Container $playing={song.id === playingSongId}>
      <Image src={song.coverImage ? `${config.storage}/${song.coverImage.relativePath}` : cover} />
      <TextWrapper>
        <SecondText $fullscreen={fullscreen} $playing={song.id === playingSongId}>
          {song.catalogPhonogramNumber}
        </SecondText>
        <MainText $fullscreen={fullscreen} $playing={song.id === playingSongId}>
          {song.name}
        </MainText>
        <SecondText $fullscreen={fullscreen} $playing={song.id === playingSongId}>
          {song.singerName}
        </SecondText>
      </TextWrapper>
      <Duration $fullscreen={fullscreen} $playing={song.id === playingSongId}>
        00:00
      </Duration>
      <Controls>
        <StyledCgPlayButtonO onClick={play} />
        <StyledCgClose onClick={remove} />
      </Controls>
    </Container>
  )
}

export default QueueItem

type TStyleProps = {
  $fullscreen?: boolean
  $playing?: boolean
}

const Controls = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  cursor: default;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  background-color: ${(props: TStyleProps) => (props.$playing ? '#EFF2F7' : 'inherit')};
  padding: 8px;
  cursor: pointer;
  &:hover ${Controls} {
    display: ${(props: TStyleProps) => (props.$playing ? 'none' : 'flex')};
  }
`
const Image = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 8px;
  margin-right: 8px;
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`
const SecondText = styled.span`
  font-size: 12px;
  color: ${(props: DefaultThemeProps & TStyleProps) =>
    props.$fullscreen ? (props.$playing ? 'black' : 'white') : props.theme.text.secondary};
`
const MainText = styled.span`
  color: ${(props: DefaultThemeProps & TStyleProps) =>
    props.$fullscreen ? (props.$playing ? 'black' : 'white') : props.theme.text.secondary};
  margin: 2px 0;
`
const Duration = styled.div`
  color: ${(props: DefaultThemeProps & TStyleProps) =>
    props.$fullscreen ? (props.$playing ? 'black' : 'white') : props.theme.text.secondary};
`

const StyledCgPlayButtonO = styled(CgPlayButtonO)`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  cursor: pointer;
`
const StyledCgClose = styled(CgClose)`
  width: 32px;
  height: 32px;
  cursor: pointer;
`
