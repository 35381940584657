import React from 'react'

const Recovery = () => {
  return (
    <div>
      <div>Recovery</div>
    </div>
  )
}

export default Recovery
