import React, { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { DefaultThemeProps } from 'styles/types'
import { ReactComponent as AddInQueueIcon } from 'assets/icons/add_in_queue.svg'
import { ReactComponent as AddOutOfQueueIcon } from 'assets/icons/add_out_of_queue.svg'
import { ReactComponent as AddInFavoritesIcon } from 'assets/icons/favorites.svg'
import { ReactComponent as AddInPlaylistIcon } from 'assets/icons/add_in_playlist.svg'
import { ReactComponent as CreatePlaylistIcon } from 'assets/icons/create_playlist.svg'
import { screens } from 'styles/screens'
import { IoClose } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { TSong } from 'store/modules/catalog/types'
import { useDispatch, useSelector } from 'react-redux'
import { addSongInQueue } from 'store/modules/player/reducer'
import { TSongInPlaylist } from 'store/modules/playlist/types'
import { getQueueFromStore } from 'store/modules/player/selectors'

type TProps = {
  closeMenu: () => void
  song: TSong | TSongInPlaylist
}

const Menu: FC<TProps> = ({ closeMenu, song }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const queue = useSelector(getQueueFromStore)

  const ref = useRef<HTMLDivElement | null>(null)

  const [show, setShow] = useState(false)
  const hideSubMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setShow(false)
  }
  const toggleShowSubMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setShow((prevState) => !prevState)
  }

  const handleClickOutside = (event: any) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      closeMenu()
    }
  }

  const addToQueue = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()

    if (queue.includes(song as TSong)) {
      closeMenu()
      return
    }

    dispatch(addSongInQueue(song as TSong))
    closeMenu()
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return function () {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <Container id="menu" ref={ref}>
      <MenuWrapper>
        <StyledCloseIcon onClick={closeMenu} />

        <MenuItem onClick={addToQueue}>
          <AddInQueueIcon />
          <Text>{t('catalog.menu.add_to_queue')}</Text>
        </MenuItem>
        <MenuItem onClick={hideSubMenu}>
          <AddOutOfQueueIcon />
          <Text>{t('catalog.menu.add_out_of_order')}</Text>
        </MenuItem>
        <MenuItem onClick={hideSubMenu}>
          <AddInFavoritesIcon />
          <Text>{t('catalog.menu.add_to_favorites')}</Text>
        </MenuItem>
        <MenuItem onClick={toggleShowSubMenu}>
          <AddInPlaylistIcon />
          <Text>{t('catalog.menu.add_to_playlist')}</Text>
        </MenuItem>
      </MenuWrapper>

      {show && (
        <SubMenu>
          <MenuItem onClick={hideSubMenu}>
            <CreatePlaylistIcon />
            <Text>{t('catalog.menu.create_playlist')}</Text>
          </MenuItem>
          <MenuItem onClick={hideSubMenu}>
            <AddInPlaylistIcon />
            <Text>{t('catalog.menu.to_my_playlist')}</Text>
          </MenuItem>
        </SubMenu>
      )}
    </Container>
  )
}

export default Menu

const Container = styled.div`
  z-index: 50;
  position: absolute;
  top: 50px;
  right: 0;
`
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${(props: DefaultThemeProps) => props.theme.background.primary};
  box-shadow: ${(props: DefaultThemeProps) => props.theme.box_shadow.primary};
  border-radius: 8px;
  padding-bottom: 16px;
`
const StyledCloseIcon = styled(IoClose)`
  cursor: pointer;
  padding: 16px 16px 0 0;
  align-self: flex-end;
`
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  &:hover {
    background-color: ${(props: DefaultThemeProps) => props.theme.background.hover};
  }
`
const Text = styled.div`
  margin-left: 8px;
`
const SubMenu = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  position: absolute;
  transform: translate(-95%, -50%);
  background-color: ${(props: DefaultThemeProps) => props.theme.background.primary};
  box-shadow: ${(props: DefaultThemeProps) => props.theme.box_shadow.primary};
  border-radius: 8px;
  padding: 16px 0;
  @media (max-width: ${screens.md}px) {
    width: 100%;
    transform: translate(0, -10%);
  }
`
