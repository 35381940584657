import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ERoutes } from 'routes/routes'
import styled, { css } from 'styled-components'
import { ReactComponent as MainIcon } from 'assets/icons/main.svg'
import { ReactComponent as CatalogIcon } from 'assets/icons/catalog.svg'
import { ReactComponent as ArtistsIcon } from 'assets/icons/artists.svg'
import { ReactComponent as FavoritesIcon } from 'assets/icons/favorites.svg'
import { ReactComponent as PlaylistsIcon } from 'assets/icons/my_playlists.svg'
import { useTranslation } from 'react-i18next'
import { DefaultThemeProps } from 'styles/types'
import { useDispatch, useSelector } from 'react-redux'
import { getIsMobileDevise } from 'store/modules/ui/selectors'
import { setShowSidebar } from 'store/modules/ui/reducer'

const items = [
  { route: ERoutes.home, textKey: 'navigation.main', icon: MainIcon },
  { route: ERoutes.catalog, textKey: 'navigation.catalog', icon: CatalogIcon },
  { route: ERoutes.artists, textKey: 'navigation.artists', icon: ArtistsIcon },
  { route: ERoutes.favorites, textKey: 'navigation.favorites', icon: FavoritesIcon },
  { route: ERoutes.playlists, textKey: 'navigation.myPlaylists', icon: PlaylistsIcon },
]

const Navigation = () => {
  const dispatch = useDispatch()
  const isMobileDevice = useSelector(getIsMobileDevise)

  const { t } = useTranslation()

  const location = useLocation()

  const hideMenu = () => {
    if (isMobileDevice) dispatch(setShowSidebar(false))
  }

  return (
    <Nav>
      {items.map((item) => {
        return (
          <StyledLink
            onClick={hideMenu}
            key={item.textKey}
            $isActive={location.pathname.split('/')[1] === item.route.split('/')[1]}
            to={item.route}
          >
            <item.icon />
            <Text>{t(item.textKey)}</Text>
          </StyledLink>
        )
      })}
    </Nav>
  )
}

export default Navigation

type TStyledProps = {
  $isActive: boolean
}

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
`
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
  background-color: ${(props: TStyledProps & DefaultThemeProps) => props.$isActive && props.theme.background.active};
  padding: 10px;
  &:last-child {
    margin-top: 70px;
  }
  & svg path,
  rect {
    ${(props: TStyledProps & DefaultThemeProps) =>
      props.$isActive &&
      css`
        stroke: ${(props: DefaultThemeProps) => props.theme.text.active};
      `};
  }
`
const Text = styled.span`
  color: ${(props: DefaultThemeProps) => props.theme.text.secondary};
  margin-left: 20px;
`
