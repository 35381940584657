import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getIsMobileDevise, getShowSidebar } from 'store/modules/ui/selectors'
import { setIsMobileDevise, setShowSidebar } from 'store/modules/ui/reducer'
import { screens } from 'styles/screens'
import { DefaultThemeProps } from 'styles/types'
import Header from 'shared/layout/header'
import SideBar from 'shared/layout/sidebar'

const Layout = () => {
  const dispatch = useDispatch()
  const isMobileDevice = useSelector(getIsMobileDevise)
  const showSidebar = useSelector(getShowSidebar)
  const showHideSidebar = () => dispatch(setShowSidebar(!showSidebar))

  const onResize = () => {
    if (window.innerWidth <= screens.xl) {
      dispatch(setIsMobileDevise(true))
      dispatch(setShowSidebar(false))
    } else {
      dispatch(setIsMobileDevise(false))
      dispatch(setShowSidebar(true))
    }
  }

  useEffect(() => {
    if (window.innerWidth <= screens.xl) {
      dispatch(setIsMobileDevise(true))
      dispatch(setShowSidebar(false))
    } else {
      dispatch(setIsMobileDevise(false))
      dispatch(setShowSidebar(true))
    }
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <Container>
      <Header showHideSidebar={showHideSidebar} showSidebar={showSidebar} isMobileDevice={isMobileDevice} />
      <Wrapper>
        {showSidebar && (
          <SidebarWrapper $isMobileDevice={isMobileDevice}>
            <SideBar />
          </SidebarWrapper>
        )}
        <OutletWrapper>
          <Outlet />
        </OutletWrapper>
      </Wrapper>
    </Container>
  )
}

export default Layout

type TStyledProps = {
  $isMobileDevice: boolean
}

const Container = styled.div`
  max-width: 1920px;
  min-width: 360px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 auto;
`
const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
`
const SidebarWrapper = styled.div`
  width: 15%;
  min-width: 250px;
  max-width: 250px;
  background-color: ${(props: DefaultThemeProps) => props.theme.background.secondary};
  border-right: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};

  ${(props: TStyledProps) =>
    props.$isMobileDevice &&
    css`
      width: unset;
      min-width: unset;
      max-width: unset;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
    `}
`
const OutletWrapper = styled.div`
  width: 100%;
  height: 94vh;
  overflow-y: auto;
  overflow-x: hidden;
`
