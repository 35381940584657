import React, { FC } from 'react'
import styled from 'styled-components'
import { DefaultThemeProps, themeNames } from 'styles/types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setTheme } from 'store/modules/ui/reducer'
import { getTheme } from 'store/modules/ui/selectors'
import { deactivateToken } from 'store/modules/profile/actions'
import { setUser } from 'store/modules/profile/reducer'
import { Link, useNavigate } from 'react-router-dom'
import { ERoutes } from 'routes/routes'
import { getUser } from 'store/modules/profile/selectors'

type TProps = {
  hideSettingsModal: () => void
}

const SettingsModal: FC<TProps> = ({ hideSettingsModal }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useSelector(getTheme)
  const user = useSelector(getUser)

  const { t, i18n } = useTranslation()
  const changeLanguage = async (lng: string) => {
    await i18n.changeLanguage(lng)
  }

  const clearUser = () => dispatch(setUser(null))
  const logout = () => dispatch(deactivateToken(clearUser))

  const goSignIn = () => navigate(ERoutes.sign_in)

  const langRu = async () => {
    await changeLanguage('ru')
    hideSettingsModal()
  }
  const langEn = async () => {
    await changeLanguage('en')
    hideSettingsModal()
  }
  const lightTheme = () => {
    dispatch(setTheme(themeNames.light))
    hideSettingsModal()
  }
  const darkTheme = () => {
    dispatch(setTheme(themeNames.dark))
    hideSettingsModal()
  }

  return (
    <Container onClick={hideSettingsModal}>
      <Menu onClick={(e) => e.stopPropagation()}>
        {user && (
          <Account to={ERoutes.profile} onClick={hideSettingsModal}>
            <Text>{t('settings.account')}</Text>
          </Account>
        )}
        <Settings>
          <LangWrapper>
            <Text>{t('settings.language')}</Text>
            <ButtonsWrapper>
              <Button onClick={langEn} disabled={i18n.language === 'en'}>
                en
              </Button>
              <Button onClick={langRu} disabled={i18n.language === 'ru'}>
                ru
              </Button>
            </ButtonsWrapper>
          </LangWrapper>
          <ThemeWrapper>
            <Text>{t('settings.theme')}</Text>
            <ButtonsWrapper>
              <Button onClick={lightTheme} disabled={theme === themeNames.light}>
                light
              </Button>
              <Button onClick={darkTheme} disabled={theme === themeNames.dark}>
                dark
              </Button>
            </ButtonsWrapper>
          </ThemeWrapper>
        </Settings>
        <LogInOut onClick={user ? logout : goSignIn}>
          <Text>{user ? t('settings.logout') : t('settings.login')}</Text>
        </LogInOut>
      </Menu>
    </Container>
  )
}

export default SettingsModal

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`
const Menu = styled.div`
  width: 220px;
  border-radius: 8px;
  background-color: ${(props: DefaultThemeProps) => props.theme.background.primary};
  box-shadow: ${(props: DefaultThemeProps) => props.theme.box_shadow.primary};
  margin-top: 50px;
  margin-right: 20px;
`
const Account = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${(props: DefaultThemeProps) => props.theme.text.primary};
  cursor: pointer;
  border-bottom: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  padding: 16px;
`
const Settings = styled.div`
  border-bottom: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  padding: 16px;
`
const LogInOut = styled.div`
  cursor: pointer;
  padding: 16px;
`
const ThemeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const LangWrapper = styled(ThemeWrapper)`
  margin-bottom: 16px;
`
const Text = styled.span`
  display: block;
`
const ButtonsWrapper = styled.div``
const Button = styled.button`
  &:not(:last-child) {
    margin-right: 5px;
  }
`
