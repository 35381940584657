import React, { FC } from 'react'
import { AutoSizer, List, ListRowProps } from 'react-virtualized'
import styled from 'styled-components'
import '../styles/tableInner.css'
import { TSongInPlaylist } from 'store/modules/playlist/types'
import Row from 'shared/songsTable/tableRow'

type TProps = {
  songs: TSongInPlaylist[]
  onSongClick: (song: TSongInPlaylist) => void
}

const Songs: FC<TProps> = ({ songs, onSongClick }) => {
  const rowRenderer = ({ index, key, style }: ListRowProps) => {
    const song = songs[index]
    return (
      <RowWrapper key={key} style={style} onClick={() => onSongClick(song)}>
        {song ? <Row song={song} index={index} /> : 'Loading...'}
      </RowWrapper>
    )
  }

  return (
    <AutoSizerWrapper>
      {songs.length > 0 ? (
        <AutoSizer>
          {({ height, width }) => (
            <List width={width} height={height} rowCount={songs.length} rowHeight={64} rowRenderer={rowRenderer} />
          )}
        </AutoSizer>
      ) : (
        <EmptyList>Песен неть ... :(((</EmptyList>
      )}
    </AutoSizerWrapper>
  )
}

export default Songs

const AutoSizerWrapper = styled.div`
  height: 80vh;
  flex: 1 1 auto;
`
const EmptyList = styled.div`
  text-align: center;
  padding: 30px 10px;
`
const RowWrapper = styled.div``
