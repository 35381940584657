import React, { FC } from 'react'
import styled from 'styled-components'
import { IoClose } from 'react-icons/io5'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { config } from 'config/config'

type TProps = {
  onClick?: () => void
}

export const TermsOfUse: FC<TProps> = (props: TProps) => {
  return (
    <PopUpContainer>
      <PopUpWindow>
        <Header>
          <Logo />
          <StyledCloseIcon onClick={props.onClick} />
        </Header>
        <TextIframe src={config.baseUrl + '/documentation/agreement'} />
      </PopUpWindow>
    </PopUpContainer>
  )
}

const PopUpContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(248, 249, 251, 0.3);
  z-index: 10;
  color: black;
  text-align: left;
`
const StyledCloseIcon = styled(IoClose)`
  cursor: pointer;
`
const PopUpWindow = styled.div`
  width: 80%;
  height: 60vh;
  background: #ffffff;
  box-shadow: 0 2px 16px #ccd0d9;
  border-radius: 16px;
  padding: 24px;
  overflow: hidden;
`
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`
const TextIframe = styled.iframe`
  height: 90%;
  width: 100%;
  border: none;
  line-height: 2;
  overflow-y: auto;
  font-size: 18px;
`
