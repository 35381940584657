import React, { ChangeEvent, useState } from 'react'
import { GoBack } from 'components/goBack'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { SignUpPopUp } from 'components/signUpPopUp'
import { TSignUpForm } from 'store/modules/profile/types'
import { signUp } from 'store/modules/profile/actions'
import { ERoutes } from 'routes/routes'
import { Link } from 'react-router-dom'
import { Main } from 'shared/ui/containers'
import { Form, FormWrapper } from 'shared/ui/form'
import { Input, InputWrapper, Label } from 'shared/ui/input'
import { Button } from 'shared/ui/button'
import { A } from 'shared/ui/link'
import { TermsOfUse } from 'components/termOfUse'
import styled from 'styled-components'

const SignUp = () => {
  const dispatch = useDispatch()

  const [passwordRepeat, setPasswordRepeat] = useState('')
  const passwordRepeatHandler = (e: React.FormEvent<HTMLInputElement>) => setPasswordRepeat(e.currentTarget.value)

  const [show, setShow] = useState(false)
  const showPopUp = () => setShow(true)

  const { register, handleSubmit, watch } = useForm<TSignUpForm>()

  const passwordsISEqual = watch('password') === passwordRepeat

  const onSubmit: SubmitHandler<TSignUpForm> = (data) => {
    dispatch(signUp(data, showPopUp))
  }

  const [termOfUse, setTermOfUse] = useState(false)
  const agreeTermOfUse = (e: ChangeEvent<HTMLInputElement>) => {
    setTermOfUse(e.currentTarget.checked)
  }

  const [showTermOfUse, setShowTermOfUse] = useState(false)
  const toggleShowTermOfUse = () => {
    setShowTermOfUse((prevState) => !prevState)
  }

  return (
    <Main>
      {show && <SignUpPopUp />}
      {showTermOfUse && <TermsOfUse onClick={toggleShowTermOfUse} />}

      <GoBack text="Home page" redirectTo={ERoutes.home} />
      <FormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper>
            <Label htmlFor="email">Email</Label>
            <Input id="email" type="email" {...register('email', { required: true })} />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="firstName">First name</Label>
            <Input id="firstName" type="string" {...register('firstName', { required: true })} />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="phone">Phone</Label>
            <Input id="phone" type="tel" {...register('phone', { required: true })} />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="password">Password</Label>
            <Input id="password" type="password" {...register('password', { required: true })} />
          </InputWrapper>

          <InputWrapper>
            <Label htmlFor="repeat-password">Repeat password</Label>
            <Input
              id="repeat-password"
              name="repeat-password"
              type="password"
              onInput={passwordRepeatHandler}
              value={passwordRepeat}
            />
          </InputWrapper>

          <Button disabled={!passwordsISEqual || !termOfUse}>Sign up</Button>

          <TermOfUseWrapper>
            <CheckBox type="checkbox" id="termOfUse" name="termOfUse" checked={termOfUse} onChange={agreeTermOfUse} />
            <label htmlFor="termOfUse" />
            <TextWrapper>
              <Text>Я ознакомлен и принимаю условия</Text>
              <TermsOfUseLink onClick={toggleShowTermOfUse}>Пользовательского соглашения</TermsOfUseLink>
            </TextWrapper>
          </TermOfUseWrapper>

          <Link to={ERoutes.sign_in}>
            <A>Sing in</A>
          </Link>
        </Form>
      </FormWrapper>
    </Main>
  )
}
export default SignUp

const TermOfUseWrapper = styled.div`
  display: flex;
  margin: 20px 0;
`
const CheckBox = styled.input`
  margin-right: 10px;
  cursor: pointer;
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`
const Text = styled.span``
const TermsOfUseLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`
