import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TMainState, TPlaylistInList, TSongInPopularList } from 'store/modules/main/types'
import { TSong } from 'store/modules/catalog/types'

const initialState: TMainState = {
  onlyFreshestPlaylists: [],
  onlyNonFreshestPlaylists: [],
  popularNow: [],
}

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setOnlyFreshestPlaylists(state: TMainState, action: PayloadAction<TPlaylistInList[]>) {
      state.onlyFreshestPlaylists = action.payload
    },
    setOnlyNonFreshestPlaylists(state: TMainState, action: PayloadAction<TPlaylistInList[]>) {
      state.onlyNonFreshestPlaylists = action.payload
    },
    setPopularNow(state: TMainState, action: PayloadAction<TSong[]>) {
      state.popularNow = action.payload
    },
  },
})

export const { setOnlyFreshestPlaylists, setOnlyNonFreshestPlaylists, setPopularNow } = mainSlice.actions

export default mainSlice.reducer
