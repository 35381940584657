import React, { useEffect } from 'react'
import Footer from 'shared/layout/footer'
import { useScrollInToView } from 'hooks/useScrollInToView'
import styled from 'styled-components'
import { screens } from 'styles/screens'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getPlaylist } from 'store/modules/playlist/actions'
import { getPlaylistFromStore } from 'store/modules/playlist/selectors'
import { config } from 'config/config'
import Songs from 'shared/songsTable/table'
import { setPlaylist } from 'store/modules/playlist/reducer'
import { ERoutes } from 'routes/routes'
import { setPlayingSongParams } from 'store/modules/player/reducer'
import { TSongInPlaylist } from 'store/modules/playlist/types'

const Playlist = () => {
  const dispatch = useDispatch()
  const { ref } = useScrollInToView()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  const playlist = useSelector(getPlaylistFromStore)

  useEffect(() => {
    if (id) dispatch(getPlaylist(id))
    return function () {
      dispatch(setPlaylist(null))
    }
  }, [])

  const onSongClick = (song: TSongInPlaylist) => {
    dispatch(
      setPlayingSongParams({
        id: song.id,
        catalogPhonogramNumber: song.catalogPhonogramNumber,
        name: song.name,
        singerName: song.singerName,
      }),
    )
    navigate(ERoutes.player)
  }

  return (
    <>
      {playlist ? (
        <Container ref={ref}>
          <Image
            src={
              playlist.banner
                ? `${config.storage}/${playlist.banner.relativePath}`
                : `https://via.placeholder.com/1000x300.png?text=${playlist?.name || ''}`
            }
          />
          <Wrapper>
            <Title>{playlist.name}</Title>
            <BriefDescription>{playlist.briefDescription}</BriefDescription>
            <Description>{playlist.description}</Description>

            <Songs songs={playlist.phonograms} onSongClick={onSongClick} />

            <Footer />
          </Wrapper>
        </Container>
      ) : null}
    </>
  )
}

export default Playlist

const Container = styled.div``
const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  margin: 0 0 16px 0;
`
const BriefDescription = styled.h2`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 8px 0;
`
const Description = styled.div`
  margin-bottom: 16px;
`
const Image = styled.img`
  width: 100%;
  height: calc(var(--index) * 8);
  object-fit: cover;
  margin-bottom: 16px;
`
const Wrapper = styled.div`
  padding: 0 20px;
  @media (max-width: ${screens.md}px) {
    padding: 0 10px;
  }
`
