import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { screens } from 'styles/screens'
import Filters from 'pages/catalog/filters'
import Footer from 'shared/layout/footer'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFiltersFromStore,
  getHasMoreSongsFromStore,
  getOffsetFromStore,
  getSongsFromStore,
} from 'store/modules/catalog/selectors'
import { setFilters } from 'store/modules/catalog/reducer'
import { getSongs } from 'store/modules/catalog/actions'
import { LIMIT } from 'shared/constants'
import SongsWithInfiniteLoading from 'shared/songsTable/tableWithInfiniteLoading'
import { useScrollInToView } from 'hooks/useScrollInToView'
import { useNavigate } from 'react-router-dom'
import { ERoutes } from 'routes/routes'
import { setPlayingSongParams } from 'store/modules/player/reducer'
import { TSong } from 'store/modules/catalog/types'

const Catalog = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { ref } = useScrollInToView()

  const songs = useSelector(getSongsFromStore)
  const hasSongsMore = useSelector(getHasMoreSongsFromStore)
  const offset = useSelector(getOffsetFromStore)
  const filters = useSelector(getFiltersFromStore)

  const onSongClick = (song: TSong) => {
    dispatch(
      setPlayingSongParams({
        id: song.id,
        catalogPhonogramNumber: song.catalogPhonogramNumber,
        name: song.name,
        singerName: song.singerName,
      }),
    )
    navigate(ERoutes.player)
  }

  useEffect(() => {
    dispatch(getSongs('guest/phonograms', false, LIMIT, undefined, filters.searchStr, filters.genreId))
  }, [filters])

  useEffect(() => {
    return function () {
      dispatch(
        setFilters({
          genreId: undefined,
          searchStr: undefined,
        }),
      )
    }
  }, [])

  return (
    <Container ref={ref}>
      <Image src={`https://via.placeholder.com/1000x300.png?text=${'Каталог'}`} alt="hero" />
      <Wrapper>
        <Title>{t('catalog.title')}</Title>
        <SubTitle>{t('catalog.sub_title')}</SubTitle>
        <Filters />
        <SongsWithInfiniteLoading
          url={'guest/phonograms'}
          songs={songs}
          hasSongsMore={hasSongsMore}
          offset={offset}
          filters={filters}
          onSongClick={onSongClick}
        />
        <Footer />
      </Wrapper>
    </Container>
  )
}

export default Catalog

const Container = styled.div``
const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  margin: 0 0 8px 0;
`
const SubTitle = styled.h2`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 16px 0;
`
const Image = styled.img`
  width: 100%;
  height: calc(var(--index) * 8);
  object-fit: cover;
  margin-bottom: 16px;
`
const Wrapper = styled.div`
  padding: 0 20px;
  @media (max-width: ${screens.md}px) {
    padding: 0 10px;
  }
`
