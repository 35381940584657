import React, { FC, useState } from 'react'
import Settings from 'pages/player/settings'
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg'
import { ReactComponent as PauseIcon } from 'assets/icons/pause.svg'
import { ReactComponent as NextIcon } from 'assets/icons/next.svg'
import { ReactComponent as HalfScreenIcon } from 'assets/icons/half_screen.svg'
import { ReactComponent as FullScreenIcon } from 'assets/icons/fullscreen.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import styled from 'styled-components'
import { addSeconds, format } from 'date-fns'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { TSongInfo } from 'store/modules/player/types'

type TProps = {
  volume: number
  playbackRate: number
  onVolumeChange: (value: number | number[]) => void
  onPlaybackRateChange: (value: number | number[]) => void
  playing: boolean
  togglePlaying: () => void
  fullscreen: boolean
  toggleFullscreen: () => void
  duration: number
  progress: number
  rewind: (time: number) => void
  playingSongParams: TSongInfo | null
  playNext: () => void
}

const trackStyle = { height: 4, backgroundColor: 'white', borderRadius: 4 }
const railStyle = {
  height: 4,
  borderRadius: 4,
  backgroundColor: '#35383D',
}
const handleStyle = {
  display: 'none',
}

const Controls: FC<TProps> = ({
  volume,
  onVolumeChange,
  playbackRate,
  onPlaybackRateChange,
  playing,
  togglePlaying,
  fullscreen,
  toggleFullscreen,
  duration,
  progress,
  rewind,
  playingSongParams,
  playNext,
}) => {
  const [showSettings, setSettings] = useState(false)
  const toggleShowSettings = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    setSettings((prevState) => !prevState)
  }

  const convertDuration = format(addSeconds(new Date(0), Math.round(duration)), 'mm:ss')
  const convertProgress = format(addSeconds(new Date(0), Math.round(progress)), 'mm:ss')

  const onRangeChange = (value: number | number[]) => {
    rewind(+value)
  }

  return (
    <Container>
      <SongInfo>
        <SecondText>{playingSongParams?.catalogPhonogramNumber || ''}</SecondText>
        <MainText>{playingSongParams?.name || ''}</MainText>
        <SecondText>{playingSongParams?.singerName || ''}</SecondText>
      </SongInfo>

      <Playing>
        <Slider
          min={0}
          max={duration}
          step={0.1}
          value={progress}
          onChange={onRangeChange}
          trackStyle={trackStyle}
          railStyle={railStyle}
          handleStyle={handleStyle}
          style={{
            marginRight: 16,
            marginLeft: 16,
          }}
        />
        <Time>{convertProgress}</Time>
        <span>&nbsp;/&nbsp;</span>
        <Time>{convertDuration}</Time>
      </Playing>

      {showSettings && (
        <Settings
          volume={volume}
          onVolumeChange={onVolumeChange}
          playbackRate={playbackRate}
          onPlaybackRateChange={onPlaybackRateChange}
          toggleShowSettings={toggleShowSettings}
        />
      )}

      <Buttons>
        <ButtonWrapper onClick={togglePlaying}>{playing ? <PauseIcon /> : <PlayIcon />}</ButtonWrapper>
        <ButtonWrapper onClick={playNext}>
          <NextIcon />
        </ButtonWrapper>
        <ButtonWrapper onClick={toggleFullscreen}>{fullscreen ? <HalfScreenIcon /> : <FullScreenIcon />}</ButtonWrapper>
        <ButtonWrapper>
          <SettingsIcon onClick={toggleShowSettings} />
        </ButtonWrapper>
      </Buttons>
    </Container>
  )
}

export default Controls

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(360deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0;
  padding: 20px;
  &:hover {
    opacity: 1;
  }
`
const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
`
const SecondText = styled.span`
  color: #35383d;
`
const MainText = styled.span`
  color: white;
  margin: 8px 0;
`
const Playing = styled.div`
  display: flex;
  align-items: center;
  color: white;
  padding: 16px 0;
`
const Time = styled.div``
const Buttons = styled.div`
  display: flex;
  align-items: center;
`
const ButtonWrapper = styled.div`
  display: flex;
  cursor: pointer;
  &:nth-child(2) {
    margin-right: auto;
  }
  &:nth-child(3) {
    margin-right: 16px;
  }
`
