import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { DefaultThemeProps } from 'styles/types'
import { IoClose } from 'react-icons/io5'
import { TEditUserDTO, TUser } from 'store/modules/profile/types'
import { useDispatch } from 'react-redux'
import { SubmitHandler, useForm } from 'react-hook-form'
import { editProfile } from 'store/modules/profile/actions'
import Button from 'components/button'
import { useTranslation } from 'react-i18next'
import { screens } from 'styles/screens'

type TProps = {
  showHideEditModal: () => void
  user: TUser
}

const formDefaultValues = {
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  guestUserProfile: {
    enableNotifications: true,
  },
}

const EditProfile: FC<TProps> = ({ showHideEditModal, user }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { register, handleSubmit, reset } = useForm<TUser>({
    defaultValues: formDefaultValues,
  })

  const onSubmit: SubmitHandler<TUser> = (data) => {
    const dto: TEditUserDTO = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      enableNotifications: data.guestUserProfile.enableNotifications,
    }
    dispatch(editProfile(dto, showHideEditModal))
  }

  useEffect(() => {
    reset(user)
  }, [user])

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledCloseIcon onClick={showHideEditModal} />
        <InputWrapper>
          <Label htmlFor="firstName">{t('profile.edit.first_name')}</Label>
          <Input id="firstName" type="string" {...register('firstName', { required: true })} />
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor="lastName">{t('profile.edit.last_name')}</Label>
          <Input id="lastName" type="string" {...register('lastName')} />
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor="email">{t('profile.edit.email')}</Label>
          <Input id="email" type="email" {...register('email', { required: true })} />
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor="phone">{t('profile.edit.phone')}</Label>
          <Input id="phone" type="tel" {...register('phone', { required: true })} />
        </InputWrapper>

        <Checkbox>
          <Input id="notifications" type="checkbox" {...register('guestUserProfile.enableNotifications')} />
          <Label htmlFor="notifications">{t('profile.edit.notification')}</Label>
        </Checkbox>

        <Button title={t('profile.edit.button')} />
      </Form>
    </Container>
  )
}

export default EditProfile

export const Container = styled.div`
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  backdrop-filter: blur(5px);
`
export const Form = styled.form`
  width: 30%;
  display: flex;
  flex-direction: column;
  background-color: ${(props: DefaultThemeProps) => props.theme.background.primary};
  border: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  border-radius: 8px;
  box-shadow: ${(props: DefaultThemeProps) => props.theme.box_shadow.primary};
  padding: 20px;
  @media (max-width: ${screens.xl}px) {
    width: 50%;
  }
  @media (max-width: ${screens.lg}px) {
    width: 80%;
  }
`
const StyledCloseIcon = styled(IoClose)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  align-self: flex-end;
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`
const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`
const Label = styled.label`
  font-size: 12px;
  margin-bottom: 2px;
`
const Input = styled.input`
  border: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  border-radius: 8px;
  padding: 10px;
`
