import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from 'components/button'

const Subscription = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('subscription.title')}</Title>
      <Text>Подписка оформлена</Text>
      <Text>299 руб. в месяц. Следующая оплата 16 Октября</Text>
      <Button title={t('subscription.button')} />
    </Container>
  )
}

export default Subscription

export const Container = styled.div``
export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 16px 0;
`
export const Text = styled.span`
  display: block;
  margin-bottom: 16px;
`
