import { useRef, useLayoutEffect } from 'react'

export const useScrollInToView = () => {
  const ref = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    if (ref.current) ref.current.scrollIntoView()
  }, [])

  return { ref }
}
