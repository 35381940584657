import { createGlobalStyle } from 'styled-components'
import { DefaultThemeProps } from 'styles/types'
import { screens } from 'styles/screens'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${(props: DefaultThemeProps) => props.theme.background.primary};
    color: ${(props: DefaultThemeProps) => props.theme.text.primary};

    @media (max-width: ${screens.lg}px) {
      font-size: calc(var(--index) * 1);
    }

    -webkit-tap-highlight-color: transparent;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(33, 100, 169, 0.15);
    border-radius: 2px;
  }

  :root {
    --index: calc(1vw + 1vh)
  }
`
