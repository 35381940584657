import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { DefaultThemeProps } from 'styles/types'
import { ReactComponent as PLayIcon } from 'assets/icons/card_controls_play.svg'
import { ReactComponent as FavoritesIcon } from 'assets/icons/favorites.svg'
import { ReactComponent as MoreIcon } from 'assets/icons/song_more.svg'
import { screens } from 'styles/screens'
import { TSong } from 'store/modules/catalog/types'
import { config } from 'config/config'
import Menu from 'shared/songsTable/tableRow/menu'
import cover from 'assets/images/song_cover.jpg'
import { TSongInPlaylist } from 'store/modules/playlist/types'

type TProps = {
  song: TSong | TSongInPlaylist
  index: number
}

const Row: FC<TProps> = ({ song, index }) => {
  const [showMoreMenu, setShowMoreMenu] = useState(false)
  const openMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setShowMoreMenu(true)
  }
  const closeMenu = () => {
    setShowMoreMenu(false)
  }

  return (
    <Container $odd={index % 2 === 0}>
      {showMoreMenu && <Menu song={song} closeMenu={closeMenu} />}

      <ImageWrapper>
        <Image src={song.coverImage ? `${config.storage}/${song.coverImage.relativePath}` : cover} />
        <ImageHover>
          <PLayIcon />
        </ImageHover>
      </ImageWrapper>
      <CatalogNumber>{song.catalogPhonogramNumber}</CatalogNumber>
      <SongTitle>{song.name}</SongTitle>
      <Singer>{song.singerName}</Singer>
      <Duration>duration</Duration>
      <AddToFavorites>
        <FavoritesIcon />
      </AddToFavorites>
      <More onClick={openMenu}>
        <MoreIcon />
      </More>
    </Container>
  )
}

export default Row

type TStyledProps = {
  $odd: boolean
}

const Container = styled.div`
  height: 48px;
  display: grid;
  grid-template-columns: 5% 8% 34% 34% 13% 3% 3%;
  justify-items: start;
  position: relative;
  align-items: center;
  background-color: ${(props: TStyledProps & DefaultThemeProps) =>
    props.$odd ? props.theme.background.secondary : 'inherit'};
  border-radius: 8px;
  padding: 8px;
  margin-right: 8px;
  cursor: pointer;

  @media (max-width: ${screens.md}px) {
    grid-template-columns: 16% 35% 35% 5% 5%;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${(props: DefaultThemeProps) => props.theme.background.hover};

      & section {
        display: flex;
      }
    }
  }
`
const ImageWrapper = styled.div`
  height: 42px;
  width: 42px;
  min-height: 42px;
  min-width: 42px;
  position: relative;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 10px;
`
const Image = styled.img`
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 8px;
`
const ImageHover = styled.section`
  height: 100%;
  width: 100%;
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 8px;
`
const Cell = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const CatalogNumber = styled(Cell)`
  @media (max-width: ${screens.md}px) {
    display: none;
  }
`
const SongTitle = styled(Cell)``
const Singer = styled(Cell)``
const Duration = styled(Cell)`
  @media (max-width: ${screens.md}px) {
    display: none;
  }
`
const AddToFavorites = styled.div``
const More = styled.div`
  padding: 0 20px;
`
