import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ActiveArrow } from 'assets/icons/active_arrow.svg'

type TArrowProps = {
	onClick?: () => void
}

export function SampleNextArrow({ onClick }: TArrowProps) {
	return <NextArrow onClick={onClick} />
}

export function SamplePrevArrow({ onClick }: TArrowProps) {
	return <PrevArrow onClick={onClick} />
}

const NextArrow = styled(ActiveArrow)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 5px;
  margin-top: -45px;
`
const PrevArrow = styled(NextArrow)`
  transform: rotate(180deg);
  margin-right: 30px;
`
