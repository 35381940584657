import React from 'react'
import styled from 'styled-components'
import { DefaultThemeProps } from 'styles/types'
import { screens } from 'styles/screens'

const Footer = () => {
  return (
    <Container>
      <Law>
        <LawText>© 2021 ОКС - Объединённые караоке системы. Все права защищены</LawText>
        <LawText>Может содержаться информация, не предназначенная для несовершеннолетних</LawText>
      </Law>
      <About>
        <AboutText>Условия пользования интернет-сервисом</AboutText>
        <AboutText>Правообладателям</AboutText>
        <AboutText>Пользовательское соглашение</AboutText>
        <AboutText>Поддержка</AboutText>
      </About>
    </Container>
  )
}

export default Footer

const Container = styled.footer`
  font-size: 12px;
  padding: 28px 0;
  margin-bottom: 20px;
`
const Law = styled.div`
  display: flex;
  margin-bottom: 10px;
  @media (max-width: ${screens.lg}px) {
    flex-direction: column;
  }
`
const LawText = styled.div`
  padding: 2px 0;
  margin-right: 20px;
`
const About = styled.div`
  display: flex;
  @media (max-width: ${screens.lg}px) {
    flex-direction: column;
  }
`
const AboutText = styled.div`
  padding: 2px 0;
  &:not(:last-child) {
    border-right: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
    padding-right: 10px;
    @media (max-width: ${screens.lg}px) {
      border-right: none;
      padding-right: 0;
    }
  }
  &:not(:first-child) {
    padding-left: 10px;
    @media (max-width: ${screens.lg}px) {
      padding-left: 0;
    }
  }
`
