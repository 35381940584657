import React from 'react'
import styled from 'styled-components'
import { useScrollInToView } from 'hooks/useScrollInToView'

const MyPlaylists = () => {
  const { ref } = useScrollInToView()

  return <Container ref={ref}>My playlists</Container>
}

export default MyPlaylists

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`
