import React from 'react'
import styled from 'styled-components'
import ByGenres from 'pages/catalog/filters/byGenres'
import ByString from 'pages/catalog/filters/byString'

const Filters = () => {
  return (
    <Container>
      <ByGenres />
      <ByString />
    </Container>
  )
}

export default Filters

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`
