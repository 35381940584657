import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { LocalStorageTokenKey } from 'api/constants'
import { config } from 'config/config'
import { store } from 'store/store'
import { setIsLoading } from 'store/modules/loading/reducer'
import { toast } from 'react-toastify'

export const client_request = axios.create({
  baseURL: config.baseUrl,
  responseType: 'json',
})

client_request.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    store.dispatch(setIsLoading(true))

    const token = localStorage.getItem(LocalStorageTokenKey)
    if (token) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      config.headers.authorization = `Bearer ${token}`
    }
    return config
  },
  (error: AxiosError) => {
    store.dispatch(setIsLoading(false))
    if (error && error.response) {
      toast(error.response.data.error)
    }
    return Promise.reject(error)
  },
)

client_request.interceptors.response.use(
  (response: AxiosResponse) => {
    store.dispatch(setIsLoading(false))
    return response
  },
  function (error: AxiosError) {
    store.dispatch(setIsLoading(false))
    if (error && error.response) {
      toast(error.response.data.error)
    }
    if (
      (error.response && error.response.data.error === 'Unauthorized') ||
      (error.response && error.response.data.error === 'Access denied')
    ) {
      localStorage.removeItem(LocalStorageTokenKey)
    }
    return Promise.reject(error)
  },
)
