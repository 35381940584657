import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { screens } from 'styles/screens'
import Footer from 'shared/layout/footer'
import { useDispatch, useSelector } from 'react-redux'
import { LIMIT } from 'shared/constants'
import { getOffsetFromStore, getSingersFromStore, getSingersHasMoreFromStore } from 'store/modules/singers/selectors'
import { getSingers } from 'store/modules/singers/actions'
import ArtistsTable from 'pages/artists/table'
import ByAlphabet from 'pages/artists/filters/byAlphabet'
import { useScrollInToView } from 'hooks/useScrollInToView'

const Artists = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { ref } = useScrollInToView()

  const singers = useSelector(getSingersFromStore)
  const hasMoreSingers = useSelector(getSingersHasMoreFromStore)
  const offset = useSelector(getOffsetFromStore)

  const [startSymbol, setStartSymbol] = useState<string | undefined>(undefined)

  const reloadSingers = () => dispatch(getSingers(false, LIMIT))

  useEffect(() => {
    dispatch(getSingers(false, LIMIT, undefined, undefined, undefined, startSymbol))
  }, [startSymbol])

  return (
    <Container ref={ref}>
      <Image src={`https://via.placeholder.com/1000x300.png?text=${'Артисты'}`} alt="hero" />
      <Wrapper>
        <Title>{t('artists.title')}</Title>
        <ByAlphabet setStartSymbol={setStartSymbol} reloadSingers={reloadSingers} />
        <ArtistsTable singers={singers} hasMoreSingers={hasMoreSingers} offset={offset} startSymbol={startSymbol} />
        <Footer />
      </Wrapper>
    </Container>
  )
}

export default Artists

const Container = styled.div``
const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  margin: 0 0 8px 0;
`
const Image = styled.img`
  width: 100%;
  height: calc(var(--index) * 8);
  object-fit: cover;
  margin-bottom: 16px;
`
const Wrapper = styled.div`
  padding: 0 20px;
  @media (max-width: ${screens.md}px) {
    padding: 0 10px;
  }
`
