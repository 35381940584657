import { client_request } from 'api'
import { AppDispatch } from 'store/store'
import { TPlaylistFreshestFilter } from 'store/modules/main/types'
import { setOnlyFreshestPlaylists, setOnlyNonFreshestPlaylists, setPopularNow } from 'store/modules/main/reducer'

export const getPlaylists = (playlistFreshestFilter: TPlaylistFreshestFilter) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await client_request.get('/guest/playlists', {
        params: {
          playlistFreshestFilter,
        },
      })
      if (playlistFreshestFilter === 'onlyFreshestPlaylists') {
        dispatch(setOnlyFreshestPlaylists(response.data.data))
      } else if (playlistFreshestFilter === 'onlyNonFreshestPlaylists') {
        dispatch(setOnlyNonFreshestPlaylists(response.data.data))
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const getPopularNow = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await client_request.get('/guest/statistics/popular-now')

      dispatch(setPopularNow(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}
