import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TPlaylist, TPlaylistState } from 'store/modules/playlist/types'

const initialState: TPlaylistState = {
  playlist: null,
}

const playlistSlice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    setPlaylist(state: TPlaylistState, action: PayloadAction<TPlaylist | null>) {
      state.playlist = action.payload
    },
  },
})

export const { setPlaylist } = playlistSlice.actions

export default playlistSlice.reducer
