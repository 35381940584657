import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DefaultThemeProps } from 'styles/types'
import Profile from 'pages/account/profile'
import PayInfo from 'pages/account/payInfo'
import Subscription from 'pages/account/subscription'
import { screens } from 'styles/screens'
import { useScrollInToView } from 'hooks/useScrollInToView'

const Account = () => {
  const { t } = useTranslation()
  const { ref } = useScrollInToView()

  return (
    <Container ref={ref}>
      <Title>{t('account.title')}</Title>
      <Profile />
      <PayInfo />
      <Subscription />
    </Container>
  )
}

export default Account

const Container = styled.div`
  width: 50%;
  padding: 20px;
  margin: 0 auto;
  @media (max-width: ${screens.lg}px) {
    width: 100%;
  }
`
const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  border-bottom: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  padding-bottom: 32px;
  margin: 0 0 16px 0;
`
