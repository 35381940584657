import styled from 'styled-components'
import { DefaultThemeProps } from 'styles/types'
import { screens } from 'styles/screens'
import { SampleNextArrow, SamplePrevArrow } from 'components/sliderArrows'
import React from 'react'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
`
export const SliderWrapper = styled.div`
  width: 100%;
  @media (max-width: ${screens.lg}px) {
    align-self: center;
  }
`
export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 18px 0;
`

export const CardDescText = styled.span`
  font-size: calc(var(--index) * 0.5);
  color: ${(props: DefaultThemeProps) => props.theme.text.inverted};
  // @media (max-width: ${screens.xxl}px) {
  //   font-size: calc(var(--index) * 0.5);
  // }
  @media (max-width: ${screens.lg}px) {
    font-size: calc(var(--index) * 0.6);
  }
  @media (max-width: ${screens.md}px) {
    font-size: calc(var(--index) * 0.7);
  }
  @media (max-width: ${screens.sm}px) {
    font-size: calc(var(--index) * 0.8);
  }
`
export const CardTitle = styled.span`
  display: block;
  font-size: calc(var(--index) * 0.7);
  margin-bottom: 8px;
  @media (max-width: ${screens.xxl}px) {
    font-size: calc(var(--index) * 0.8);
  }
  @media (max-width: ${screens.lg}px) {
    font-size: calc(var(--index) * 0.9);
  }
  @media (max-width: ${screens.md}px) {
    font-size: calc(var(--index) * 1);
  }
  @media (max-width: ${screens.sm}px) {
    font-size: calc(var(--index) * 1.1);
  }
`
export const CardSubTitle = styled.span`
  display: block;
  font-size: calc(var(--index) * 0.5);
  color: ${(props: DefaultThemeProps) => props.theme.text.secondary};
  @media (max-width: ${screens.xxl}px) {
    font-size: calc(var(--index) * 0.6);
  }
  @media (max-width: ${screens.lg}px) {
    font-size: calc(var(--index) * 0.7);
  }
  @media (max-width: ${screens.md}px) {
    font-size: calc(var(--index) * 0.6);
  }
  @media (max-width: ${screens.sm}px) {
    font-size: calc(var(--index) * 0.9);
  }
`

export const freshest_slider_settings = {
  className: 'slider variable-width',
  variableWidth: true,
  arrows: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  swipeToSlide: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    // {
    //   breakpoint: screens.xxl,
    //   settings: {
    //     slidesToShow: 2,
    //   },
    // },
    {
      breakpoint: screens.lg,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

export const popular_slider_settings = {
  className: 'slider variable-width',
  variableWidth: true,
  arrows: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  swipeToSlide: true,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    // {
    //   breakpoint: screens.xxl,
    //   settings: {
    //     slidesToShow: 5,
    //   },
    // },
    {
      breakpoint: screens.xl,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: screens.lg,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: screens.md,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: screens.sm,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}
