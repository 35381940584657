import { client_request } from 'api'
import { AppDispatch } from 'store/store'
import { setUser } from './reducer'
import { TEditUserDTO, TSignInForm, TSignUpForm } from './types'
import { LocalStorageTokenKey } from 'api/constants'

export const getProfile = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await client_request.get('/users/guests/me')
      dispatch(setUser(response.data.data))
    } catch (error) {
      console.log(error)
    }
  }
}

export const signUp = (data: TSignUpForm, showPopUp: () => void) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await client_request.post('/users/guests/signup', data)
      localStorage.setItem(LocalStorageTokenKey, response.data.data.token)
      dispatch(getProfile())
      showPopUp()
    } catch (error) {
      console.log(error)
    }
  }
}

export const signIn = (data: TSignInForm, goHome: () => void) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await client_request.post('/users/signin/email', {
        ...data,
        type: 'guest',
      })
      localStorage.setItem(LocalStorageTokenKey, response.data.data.token)
      dispatch(getProfile())
      goHome()
    } catch (error) {
      console.log(error)
    }
  }
}

export const editProfile = (data: TEditUserDTO, closeForm?: () => void) => {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await client_request.put('/users/guests', data)
      dispatch(setUser(response.data.data))
      if (closeForm) {
        closeForm()
      }
    } catch (error) {
      console.log(error)
    }
  }
}

export const deactivateToken = (clearUser?: () => void) => {
  return async () => {
    try {
      await client_request.post('/users/logout')
      localStorage.removeItem(LocalStorageTokenKey)
      if (clearUser) {
        clearUser()
      }
    } catch (error) {
      console.log(error)
    }
  }
}
