import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import { Container, popular_slider_settings, SliderWrapper, Title } from 'shared/ui/slider'
import SquareWithControls from 'components/cards/squareWithControls'
import cover from 'assets/images/song_cover.jpg'
import { TSong } from 'store/modules/catalog/types'
import { setPlayingSongParams } from 'store/modules/player/reducer'
import { ERoutes } from 'routes/routes'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

type TProps = {
  popularNow: TSong[]
}

const Popular: FC<TProps> = ({ popularNow }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSongClick = (song: TSong) => {
    dispatch(
      setPlayingSongParams({
        id: song.id,
        catalogPhonogramNumber: song.catalogPhonogramNumber,
        name: song.name,
        singerName: song.singerName,
      }),
    )
    navigate(ERoutes.player)
  }
  return (
    <Container>
      <Title>{t('popular.title')}</Title>
      <SliderWrapper>
        <Slider {...popular_slider_settings}>
          {popularNow.map((song) => (
            <SquareWithControls
              key={song.id}
              title={song.name}
              subtitle={song.singerName}
              img={cover}
              onPlay={() => onSongClick(song)}
            />
          ))}
        </Slider>
      </SliderWrapper>
    </Container>
  )
}

export default Popular
