import React, { FC } from 'react'
import styled from 'styled-components'
import { DefaultThemeProps } from 'styles/types'
import { config } from 'config/config'
import { TSinger } from 'store/modules/singers/types'
import { screens } from 'styles/screens'
import { ERoutes } from 'routes/routes'
import { createSearchParams, useNavigate } from 'react-router-dom'

type TProps = {
  singer: TSinger
  index: number
}

const ArtistsTableRow: FC<TProps> = ({ singer, index }) => {
  const navigate = useNavigate()

  const goToSingersSelection = () => {
    navigate({
      pathname: ERoutes.selection,
      search: createSearchParams({
        title: singer.name,
        url: `/singers/${singer.id}/phonograms`,
      }).toString(),
    })
  }

  return (
    <Container onClick={goToSingersSelection} $odd={index % 2 === 0}>
      <Image
        src={
          singer.photo
            ? `${config.storage}/${singer.photo.relativePath}`
            : 'https://via.placeholder.com/64x64.png?text=A'
        }
      />
      <Name>{singer.name}</Name>
    </Container>
  )
}

export default ArtistsTableRow

type TStyledProps = {
  $odd: boolean
}

const Container = styled.div`
  height: 48px;
  display: grid;
  grid-template-columns: 4% 96%;
  justify-items: center;
  align-items: center;
  background-color: ${(props: TStyledProps & DefaultThemeProps) =>
    props.$odd ? props.theme.background.secondary : 'inherit'};
  border-radius: 8px;
  padding: 8px;
  margin-right: 8px;
  cursor: pointer;

  @media (max-width: ${screens.md}px) {
    grid-template-columns: 10% 90%;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${(props: DefaultThemeProps) => props.theme.background.hover};
    }
  }
`

const Image = styled.img`
  height: 42px;
  width: 42px;
  border-radius: 8px;
`
const Cell = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Name = styled(Cell)`
  padding-left: 20px;
`
