import { client_request } from 'api'
import { AppDispatch } from 'store/store'
import { LIMIT } from 'shared/constants'
import { setHasMoreSingers, setMoreSingers, setSingers } from 'store/modules/singers/reducer'
import { setOffset } from 'store/modules/singers/reducer'

export const getSingers = (
  getMore: boolean,
  limit: number,
  offset?: number,
  includePhoto?: boolean,
  includeBanner?: boolean,
  startSymbol?: string,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setHasMoreSingers(true))

    if (!getMore) {
      dispatch(setSingers([]))
      dispatch(setOffset(LIMIT))
    }

    try {
      const response = await client_request.get('/singers', {
        params: {
          limit,
          offset,
          includePhoto,
          includeBanner,
          startSymbol,
        },
      })
      if (response.data.data.length < limit) {
        dispatch(setHasMoreSingers(false))
      }
      if (getMore) {
        dispatch(setMoreSingers(response.data.data))
      } else {
        dispatch(setSingers(response.data.data))
      }
    } catch (error) {
      console.log(error)
    }
  }
}
