import React, { FC } from 'react'
import { ERoutes } from 'routes/routes'
import Main from 'pages/main'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Catalog from 'pages/catalog'
import Artists from 'pages/artists'
import Favorites from 'pages/favorites'
import MyPlaylists from 'pages/myPlaylists'
import SignIn from 'pages/signIn'
import SignUp from 'pages/signUp'
import Layout from 'shared/layout'
import Account from 'pages/account'
import Selection from 'pages/selection'
import Playlist from 'pages/playlist'
import Player from 'pages/player'
import Recovery from 'pages/recovery'

export const RootRoute: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ERoutes.home} element={<Layout />}>
          <Route index element={<Main />} />
          <Route path={ERoutes.catalog} element={<Catalog />} />
          <Route path={ERoutes.artists} element={<Artists />} />
          <Route path={ERoutes.favorites} element={<Favorites />} />
          <Route path={ERoutes.playlists} element={<MyPlaylists />} />
          <Route path={ERoutes.profile} element={<Account />} />
          <Route path={ERoutes.selection} element={<Selection />} />
          <Route path={ERoutes.playlist}>
            <Route index element={<Playlist />} />
            <Route path=":id" element={<Playlist />} />
          </Route>
          <Route path={ERoutes.player}>
            <Route index element={<Player />} />
            <Route path=":id" element={<Player />} />
          </Route>
        </Route>
        <Route path={ERoutes.sign_in} element={<SignIn />} />
        <Route path={ERoutes.sign_up} element={<SignUp />} />
        <Route path={ERoutes.recovery} element={<Recovery />} />
      </Routes>
    </BrowserRouter>
  )
}
