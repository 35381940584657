import React, { FC, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import QueueItem from 'pages/player/queue/queueItem'
import { DefaultThemeProps } from 'styles/types'
import { useDispatch, useSelector } from 'react-redux'
import { getQueueFromStore } from 'store/modules/player/selectors'
import { clearQueue, setPlayingSongParams } from 'store/modules/player/reducer'
import { TSong } from 'store/modules/catalog/types'

type TProps = {
  fullscreen: boolean
  toggleShowQueue: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
  playingSongId: string
}

const Queue: FC<TProps> = ({ fullscreen, toggleShowQueue, playingSongId }) => {
  const dispatch = useDispatch()
  const queue = useSelector(getQueueFromStore)

  const ref = useRef<HTMLDivElement | null>(null)
  const handleClickOutside = (event: any) => {
    if (ref && ref.current && !ref.current.contains(event.target)) {
      toggleShowQueue(event)
    }
  }

  const clear = () => {
    dispatch(setPlayingSongParams(null))
    dispatch(clearQueue([]))
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)

    return function () {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <Container $fullscreen={fullscreen} ref={ref}>
      <Title>Очередь воспроизведения</Title>
      <QueueWrapper>
        {queue.map((song: TSong) => (
          <QueueItem key={song.id} song={song} fullscreen={fullscreen} playingSongId={playingSongId} />
        ))}
      </QueueWrapper>
      <ClearButton $fullscreen={fullscreen} onClick={clear}>
        Очистить
      </ClearButton>
    </Container>
  )
}

export default Queue

type TStyleProps = {
  $fullscreen: boolean
}

const Container = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  background-color: white;

  ${(props: TStyleProps) =>
    props.$fullscreen &&
    css`
      height: 70%;
      position: fixed;
      top: 60px;
      right: 16px;
      color: white;
      background: rgba(0, 0, 0, 0.4);
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(8px);
      border-radius: 8px;
    `}
`
const Title = styled.span`
  font-size: 16px;
  padding: 16px;
`
const QueueWrapper = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0 16px;
`
const ClearButton = styled.div`
  cursor: pointer;
  text-align: center;
  border-top: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  color: ${(props: DefaultThemeProps & TStyleProps) => (props.$fullscreen ? 'white' : props.theme.text.secondary)};
  padding: 8px 0 16px;
`
