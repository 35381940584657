import { DefaultTheme } from 'styled-components'

const darkTheme: DefaultTheme = {
  background: {
    primary: 'gray',
    secondary: 'gray',
    active: '#F7F3FF',
    hover: '#EFF2F6',
  },
  text: {
    primary: '#1B1C1F',
    secondary: '#35383D',
    inverted: '#FFFFFF',
    active: '#8441FF',
  },
  border: {
    primary: '#DBE2ED',
  },
  box_shadow: {
    primary: '0 0 16px rgba(0, 0, 0, 0.2)',
  },
}

export default darkTheme
