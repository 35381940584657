import React, { useEffect } from 'react'
import styled from 'styled-components'
import { DefaultThemeProps } from 'styles/types'
import { useDispatch, useSelector } from 'react-redux'
import { getGenres } from 'store/modules/catalog/actions'
import { getFiltersFromStore, getGenresFromStore } from 'store/modules/catalog/selectors'
import { setFilters } from 'store/modules/catalog/reducer'

const ByGenres = () => {
  const dispatch = useDispatch()

  const filters = useSelector(getFiltersFromStore)

  const genres = [{ id: undefined, name: 'Все' }, ...useSelector(getGenresFromStore)]

  const onGenreButton = (genreId: string | undefined) => {
    if (filters.genreId === genreId) return
    dispatch(setFilters({ ...filters, genreId }))
  }

  useEffect(() => {
    dispatch(getGenres())
  }, [])

  return (
    <Container>
      {genres.map((genre) => {
        return (
          <Button key={genre.name} $isActive={filters.genreId === genre.id} onClick={() => onGenreButton(genre.id)}>
            <Text>{genre.name}</Text>
          </Button>
        )
      })}
    </Container>
  )
}

export default ByGenres

type TStyledProps = {
  $isActive: boolean
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 16px;
`
const Button = styled.div`
  border: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  border-radius: 8px;
  background-color: ${(props: DefaultThemeProps & TStyledProps) =>
    props.$isActive ? props.theme.background.active : 'inherit'};
  color: ${(props: DefaultThemeProps & TStyledProps) => props.$isActive && props.theme.text.active};
  padding: 6px;
  margin-bottom: 8px;
  &:first-child {
    padding: 6px 40px;
  }
  &:not(:last-child) {
    margin-right: 8px;
  }
  cursor: pointer;
`
const Text = styled.span``
