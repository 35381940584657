import React, { FC } from 'react'
import { ReactComponent as Play } from 'assets/icons/card_controls_play.svg'
import { ReactComponent as More } from 'assets/icons/card_controls_more.svg'
import styled from 'styled-components'

type TProps = {
  onPlay?: () => void
}

const CardControls: FC<TProps> = ({ onPlay }) => {
  return (
    <Hover>
      <ControlsWrapper>
        <PlayButton onClick={onPlay}>
          <Play />
        </PlayButton>
        <MoreButton>
          <More />
        </MoreButton>
      </ControlsWrapper>
    </Hover>
  )
}

export default CardControls

const Hover = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  z-index: 10;
  cursor: auto;
`
const ControlsWrapper = styled.div`
  display: flex;
`
const PlayButton = styled.div`
  width: calc(var(--index) * 1.2);
  height: calc(var(--index) * 1.2);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(27, 28, 31, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 50%;
  padding: 10px;
  margin-right: 16px;
  cursor: pointer;
`
const MoreButton = styled(PlayButton)`
  margin-right: 0;
`
