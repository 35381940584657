import React, { useState } from 'react'
import styled from 'styled-components'
import Navigation from 'shared/layout/sidebar/navigation'
import { DefaultThemeProps } from 'styles/types'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { useTranslation } from 'react-i18next'
import { TermsOfUse } from 'components/termOfUse'

const SideBar = () => {
  const { t } = useTranslation()

  const [showTermOfUse, setShowTermOfUse] = useState(false)
  const toggleShowTermOfUse = () => {
    setShowTermOfUse((prevState) => !prevState)
  }

  return (
    <Container>
      {showTermOfUse && <TermsOfUse onClick={toggleShowTermOfUse} />}

      <Search>
        <StyledSearchIcon />
        <Input placeholder={t('sidebar.search_placeholder')} />
      </Search>
      <Navigation />
      <TermsOfUseLink onClick={toggleShowTermOfUse}>Пользовательское соглашение</TermsOfUseLink>
    </Container>
  )
}

export default SideBar

const Container = styled.div`
  height: 92%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
`
const Search = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
`
const Input = styled.input`
  width: 100%;
  border: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  border-radius: 8px;
  padding: 10px 10px 10px 40px;
`
const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 10px;
  z-index: 10;
`
const TermsOfUseLink = styled.span`
  align-self: center;
  justify-self: flex-end;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
`
