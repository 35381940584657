import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TCatalogState, TFilters, TGenreItem, TSong } from 'store/modules/catalog/types'
import { LIMIT } from 'shared/constants'

const initialState: TCatalogState = {
  genres: [],
  songs: [],
  offset: LIMIT,
  hasMoreSongs: true,
  filters: {
    genreId: undefined,
    searchStr: undefined,
  },
}

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setGenres(state: TCatalogState, action: PayloadAction<TGenreItem[]>) {
      state.genres = action.payload
    },
    setSongs(state: TCatalogState, action: PayloadAction<TSong[]>) {
      state.songs = action.payload
    },
    setMoreSongs(state: TCatalogState, action: PayloadAction<TSong[]>) {
      state.songs.push(...action.payload)
    },
    setOffset(state: TCatalogState, action: PayloadAction<number>) {
      state.offset = action.payload
    },
    setHasMoreSongs(state: TCatalogState, action: PayloadAction<boolean>) {
      state.hasMoreSongs = action.payload
    },
    setFilters(state: TCatalogState, action: PayloadAction<TFilters>) {
      state.filters = action.payload
    },
  },
})

export const { setGenres, setSongs, setMoreSongs, setOffset, setHasMoreSongs, setFilters } = catalogSlice.actions

export default catalogSlice.reducer
