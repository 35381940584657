import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { DefaultThemeProps } from 'styles/types'
import Button from 'components/button'

const PayInfo = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('pay_info.title')}</Title>
      <Text>Visa .... .... .... 9999</Text>
      <Button title={t('pay_info.button')} />
    </Container>
  )
}

export default PayInfo

export const Container = styled.div`
  border-bottom: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  padding-bottom: 32px;
  margin-bottom: 16px;
`
export const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 16px 0;
`
export const Text = styled.span`
  display: block;
  margin-bottom: 16px;
`
