export const screens = {
  sm: 460,
  // => @media (min-width: 640px) { ... }
  md: 680,
  // => @media (min-width: 768px) { ... }
  lg: 1024,
  // => @media (min-width: 1024px) { ... }
  xl: 1280,
  // => @media (min-width: 1280px) { ... }
  xxl: 1536,
  // => @media (min-width: 1536px) { ... }
  xxxl: 1680,
  // => @media (min-width: 1680px) { ... }
}
