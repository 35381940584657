import React, { useEffect } from 'react'
import { RootRoute } from 'routes/root'
import { useDispatch, useSelector } from 'react-redux'
import { GlobalStyle } from 'styles/global'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { getTheme } from 'store/modules/ui/selectors'
import lightTheme from 'styles/lightTheme'
import darkTheme from 'styles/darkTheme'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getProfile } from 'store/modules/profile/actions'
import { LocalStorageTokenKey } from 'api/constants'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-virtualized/styles.css'
import { Loader } from 'components/loader'
import { getIsLoading } from 'store/modules/loading/selectors'

function App() {
  const dispatch = useDispatch()
  const isLoading = useSelector(getIsLoading)

  const themes: { [key: string]: DefaultTheme } = {
    light: lightTheme,
    dark: darkTheme,
  }

  const theme = useSelector(getTheme)

  const token = localStorage.getItem(LocalStorageTokenKey)

  useEffect(() => {
    // dispatch(getSettings())
    if (token) {
      dispatch(getProfile())
    }
  }, [])

  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalStyle />
      {isLoading && <Loader isLoading={isLoading} />}
      <ToastContainer />
      <RootRoute />
    </ThemeProvider>
  )
}

export default App
