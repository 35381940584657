import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import { ReactComponent as LogoText } from 'assets/images/logo-text.svg'
import { ReactComponent as UserAvatar } from 'assets/icons/user_avatar.svg'
import { FiMenu } from 'react-icons/fi'
import { CgClose } from 'react-icons/cg'
import { DefaultThemeProps } from 'styles/types'
import { useSelector } from 'react-redux'
import { getUser } from 'store/modules/profile/selectors'
import Settings from 'shared/layout/header/settings'
import { getQueueFromStore } from 'store/modules/player/selectors'
import { Link, useLocation } from 'react-router-dom'
import { ERoutes } from 'routes/routes'

type TProps = {
  showHideSidebar: () => void
  showSidebar: boolean
  isMobileDevice: boolean
}

const Header: FC<TProps> = ({ showSidebar, showHideSidebar, isMobileDevice }) => {
  const user = useSelector(getUser)
  const queue = useSelector(getQueueFromStore)
  const location = useLocation()

  const [show, setShow] = useState<boolean>(false)
  const showSettingsModal = () => setShow(true)
  const hideSettingsModal = () => setShow(false)

  return (
    <Container>
      {isMobileDevice && (
        <MenuBtnWrapper onClick={showHideSidebar}>{showSidebar ? <CgClose /> : <FiMenu />}</MenuBtnWrapper>
      )}
      <LogoWrapper>
        <StyledLogo />
        <LogoText />
      </LogoWrapper>
      {queue.length > 0 && location.pathname !== ERoutes.player && (
        <StyledLink to={ERoutes.player}>Перейти в плеер</StyledLink>
      )}
      <Wrapper>
        {user && <div>{user.firstName}</div>}
        <StyledUserAvatar onClick={showSettingsModal} />
      </Wrapper>
      {show && <Settings hideSettingsModal={hideSettingsModal} />}
    </Container>
  )
}

export default Header

const Container = styled.header`
  height: 46px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  padding: 0 16px;
`
const MenuBtnWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-right: 20px;
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`
const StyledLogo = styled(Logo)`
  margin-right: 10px;
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props: DefaultThemeProps) => props.theme.text.primary};
  border: 1px solid ${(props: DefaultThemeProps) => props.theme.border.primary};
  border-radius: 4px;
  padding: 5px;
`
const StyledUserAvatar = styled(UserAvatar)`
  margin-left: 20px;
  cursor: pointer;
`
