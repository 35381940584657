import React, { useEffect } from 'react'
import styled from 'styled-components'
import Freshest from 'pages/main/freshest'
import Popular from 'pages/main/popular'
import Artists from 'pages/main/artists'
import Mood from 'pages/main/mood'
import Genres from 'pages/main/genres'
import Footer from 'shared/layout/footer'
import { screens } from 'styles/screens'
import { getGenres } from 'store/modules/catalog/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getGenresFromStore } from 'store/modules/catalog/selectors'
import { getSingers } from 'store/modules/singers/actions'
import { getSingersFromStore } from 'store/modules/singers/selectors'
import { useScrollInToView } from 'hooks/useScrollInToView'
import { getPlaylists, getPopularNow } from 'store/modules/main/actions'
import {
  getOnlyFreshestPlaylists,
  getOnlyNonFreshestPlaylists,
  getPopularNowFromStore,
} from 'store/modules/main/selectors'

const Main = () => {
  const dispatch = useDispatch()
  const { ref } = useScrollInToView()

  const genres = useSelector(getGenresFromStore)
  const singers = useSelector(getSingersFromStore)
  const freshest = useSelector(getOnlyFreshestPlaylists)
  const mood = useSelector(getOnlyNonFreshestPlaylists)
  const popularNow = useSelector(getPopularNowFromStore)
  // const { t } = useTranslation()

  useEffect(() => {
    dispatch(getGenres())
    dispatch(getSingers(false, 20))
    dispatch(getPlaylists('onlyFreshestPlaylists'))
    dispatch(getPlaylists('onlyNonFreshestPlaylists'))
    dispatch(getPopularNow())
  }, [])

  return (
    <Container ref={ref}>
      {/*<Ad />*/}
      {/*<Title>{t('main.title')}</Title>*/}
      {freshest.length > 2 && <Freshest freshest={freshest} />}
      <Popular popularNow={popularNow} />
      <Artists singers={singers} />
      {mood.length > 2 && <Mood mood={mood} />}
      <Genres genres={genres} />
      {/*<Top />*/}
      {/*<Read />*/}
      <Footer />
    </Container>
  )
}

export default Main

const Container = styled.div`
  padding: 20px 0 0 20px;
  @media (max-width: ${screens.md}px) {
    padding: 10px;
  }
`
// const Title = styled.h1`
//   font-size: 30px;
//   font-weight: 500;
//   margin: 0 0 16px 0;
// `
