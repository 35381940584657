import React, { FC } from 'react'
import styled from 'styled-components'
import { screens } from 'styles/screens'
import { CardDescText, CardSubTitle, CardTitle } from 'shared/ui/slider'
import { TPlaylistInList } from 'store/modules/main/types'

type TProps = {
  playlist: TPlaylistInList
  img: string
  onClick: () => void
}

const RectangularWithDescription: FC<TProps> = ({ playlist, img, onClick }) => {
  return (
    <Card onClick={onClick}>
      <ImageWrapper>
        <Image src={img} alt="Cover" />

        <BriefDescription>
          <CardDescText>{playlist.briefDescription}</CardDescText>
        </BriefDescription>
      </ImageWrapper>

      <CardTitle>{playlist.name}</CardTitle>
      <CardDescription>{playlist.description}</CardDescription>
    </Card>
  )
}

export default RectangularWithDescription

const Card = styled.div`
  width: calc(var(--index) * 17);
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  cursor: pointer;
  @media (max-width: ${screens.lg}px) {
    width: calc(var(--index) * 21);
  }
  @media (max-width: ${screens.md}px) {
    width: calc(var(--index) * 21);
  }
  @media (max-width: ${screens.sm}px) {
    width: calc(var(--index) * 26);
  }
`
const ImageWrapper = styled.div`
  height: calc(var(--index) * 8);
  margin-bottom: 16px;
  @media (max-width: ${screens.lg}px) {
    height: calc(var(--index) * 10);
  }
  @media (max-width: ${screens.md}px) {
    height: calc(var(--index) * 10);
  }
  @media (max-width: ${screens.sm}px) {
    height: calc(var(--index) * 13);
  }
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`
const BriefDescription = styled.div`
  height: calc(var(--index) * 2);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 0 0 8px 8px;
  padding: 2px 20px;
  margin-bottom: 14px;
  margin-top: calc(var(--index) * -2.1);
`
const CardDescription = styled(CardSubTitle)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
