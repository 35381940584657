import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TSinger, TSingersState } from 'store/modules/singers/types'
import { LIMIT } from 'shared/constants'

const initialState: TSingersState = {
  singers: [],
  hasMoreSingers: true,
  offset: LIMIT,
}

const singersSlice = createSlice({
  name: 'singers',
  initialState,
  reducers: {
    setSingers(state: TSingersState, action: PayloadAction<TSinger[]>) {
      state.singers = action.payload
    },
    setMoreSingers(state: TSingersState, action: PayloadAction<TSinger[]>) {
      state.singers.push(...action.payload)
    },
    setHasMoreSingers(state: TSingersState, action: PayloadAction<boolean>) {
      state.hasMoreSingers = action.payload
    },
    setOffset(state: TSingersState, action: PayloadAction<number>) {
      state.offset = action.payload
    },
  },
})

export const { setSingers, setMoreSingers, setHasMoreSingers, setOffset } = singersSlice.actions

export default singersSlice.reducer
