import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { screens } from 'styles/screens'
import { CardSubTitle, CardTitle } from 'shared/ui/slider'
import CardControls from 'components/cards/squareWithControls/controls'

type TProps = {
  img: string
  title: string
  subtitle: string
  onPlay?: () => void
}

const SquareWithControls: FC<TProps> = ({ img, title, subtitle, onPlay }) => {
  const [show, setShow] = useState(false)
  const showControls = () => setShow(true)
  const hideControls = () => setShow(false)

  return (
    <Card>
      <ImgWrapper onMouseEnter={showControls} onMouseLeave={hideControls}>
        <Image src={img} alt="Cover" />
        {show && <CardControls onPlay={onPlay} />}
      </ImgWrapper>

      <CardTitle>{title}</CardTitle>
      <CardSubTitle>{subtitle}</CardSubTitle>
    </Card>
  )
}

export default SquareWithControls

const Card = styled.div`
  width: calc(var(--index) * 8);
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  cursor: pointer;
  @media (max-width: ${screens.lg}px) {
    width: calc(var(--index) * 10);
  }
  @media (max-width: ${screens.md}px) {
    width: calc(var(--index) * 10);
  }
  @media (max-width: ${screens.sm}px) {
    width: calc(var(--index) * 12);
  }
`
const ImgWrapper = styled.div`
  height: calc(var(--index) * 8);
  position: relative;
  border-radius: 8px;
  margin-bottom: 16px;
  @media (max-width: ${screens.lg}px) {
    height: calc(var(--index) * 10);
  }
  @media (max-width: ${screens.md}px) {
    height: calc(var(--index) * 10);
  }
  @media (max-width: ${screens.sm}px) {
    height: calc(var(--index) * 12);
  }
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  object-fit: cover;
`
