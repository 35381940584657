import React, { FC } from 'react'
import styled from 'styled-components'
import { screens } from 'styles/screens'
import { CardTitle } from 'shared/ui/slider'
import { TPlaylistInList } from 'store/modules/main/types'

type TProps = {
  playlist: TPlaylistInList
  img: string
  onClick: () => void
}

const RectangularSmall: FC<TProps> = ({ playlist, img, onClick }) => {
  return (
    <Card onClick={onClick}>
      <ImageWrapper>
        <Image src={img} alt="Cover" />
      </ImageWrapper>

      <CardTitle>{playlist.name}</CardTitle>
    </Card>
  )
}

export default RectangularSmall

const Card = styled.div`
  width: calc(var(--index) * 12);
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  cursor: pointer;
  @media (max-width: ${screens.lg}px) {
    width: calc(var(--index) * 16);
  }
  @media (max-width: ${screens.md}px) {
    width: calc(var(--index) * 16);
  }
  @media (max-width: ${screens.sm}px) {
    width: calc(var(--index) * 20);
  }
`
const ImageWrapper = styled.div`
  height: calc(var(--index) * 6);
  margin-bottom: 16px;
  @media (max-width: ${screens.lg}px) {
    height: calc(var(--index) * 8);
  }
  @media (max-width: ${screens.md}px) {
    height: calc(var(--index) * 8);
  }
  @media (max-width: ${screens.sm}px) {
    height: calc(var(--index) * 10);
  }
`
const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`
