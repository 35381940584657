import React, { FC } from 'react'
import { AutoSizer, InfiniteLoader, List, ListRowProps } from 'react-virtualized'
import styled from 'styled-components'
import '../styles/tableInner.css'
import { useDispatch } from 'react-redux'
import { getSongs } from 'store/modules/catalog/actions'
import { setOffset } from 'store/modules/catalog/reducer'
import { LIMIT } from 'shared/constants'
import { TFilters, TSong } from 'store/modules/catalog/types'
import Row from 'shared/songsTable/tableRow'

type TProps = {
  url: string
  songs: TSong[]
  hasSongsMore: boolean
  offset: number
  onSongClick: (song: TSong) => void
  filters?: TFilters
}

const SongsWithInfiniteLoading: FC<TProps> = ({ url, songs, offset, filters, hasSongsMore, onSongClick }) => {
  const dispatch = useDispatch()

  const rowRenderer = ({ index, key, style }: ListRowProps) => {
    const song = songs[index]
    return (
      <RowWrapper key={key} style={style} onClick={() => onSongClick(song)}>
        {song ? <Row song={song} index={index} /> : 'Loading...'}
      </RowWrapper>
    )
  }

  const loadMoreRows = async () => {
    await dispatch(setOffset(offset + LIMIT))
    await dispatch(
      getSongs(
        url,
        true,
        LIMIT,
        offset,
        filters ? filters.searchStr : undefined,
        filters ? filters.genreId : undefined,
      ),
    )
  }

  return (
    <AutoSizerWrapper>
      {songs.length > 0 ? (
        <InfiniteLoader
          isRowLoaded={({ index }) => !!songs[index]}
          loadMoreRows={loadMoreRows}
          rowCount={!hasSongsMore ? songs.length : Number.MAX_SAFE_INTEGER}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                  width={width}
                  height={height}
                  rowCount={songs.length}
                  rowHeight={64}
                  rowRenderer={rowRenderer}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      ) : (
        <EmptyList>Песен неть ... :(((</EmptyList>
      )}
    </AutoSizerWrapper>
  )
}

export default SongsWithInfiniteLoading

const AutoSizerWrapper = styled.div`
  height: 80vh;
  flex: 1 1 auto;
`
const EmptyList = styled.div`
  text-align: center;
  padding: 30px 10px;
`
const RowWrapper = styled.div``
